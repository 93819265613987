.wpo-faq-section {

    .wpo-section-title {
        text-align: center;

        h2 {
            display: inline-block;
        }
    }

    .accordion-item {
        border: 0;
        border: 1px solid #e1e1e1;
        padding: 5px 20px;
        margin-bottom: 20px;


        .accordion-body {
            padding-left: 0;
            padding-right: 0;
        }

        button {
            padding: 20px;
            border: 0;
            border-radius: 0;
            padding-left: 0;
            padding-right: 0;
            font-size: 18px;
            color: #373b3e;
            text-align: left;
            // font-family: $base-font;

            &::after {
                background: none;
                font-family: "themify";
                content: "\e622";
                font-size: 15px;
                transform: rotate(0deg);
                font-weight: 700;
            }


            &.collapsed {
                color: #373b3e;

                &::after {
                    content: "\e61a";
                }
            }

            &:focus {
                box-shadow: none;
                outline: none;
                border-color: #e1e1e1;

            }


        }

        .accordion-collapse {
            border: 0;
            transition: height 0.3s ease;
        }
    }

    .accordion-button {
        background: transparent;
    }
}


.wpo-benefits-section {
    padding-top: 30px;

    h2 {
        font-size: 33px;
        // font-family: ;
        margin-bottom: 30px;
        font-weight: 700;
    }

    .accordion {
        border-top: 1px solid #e1e1e1;
    }

    .accordion-item {
        border: 0;
        border-bottom: 1px solid #e1e1e1;

        .accordion-body {
            padding-left: 0;
            padding-right: 0;
        }

        button {
            padding: 20px;
            border: 0;
            border-radius: 0;
            padding-left: 0;
            padding-right: 0;
            font-size: 20px;
            // color: $heading-color;
            text-align: left;

            &::after {
                background: none;
                font-family: "themify";
                content: "\e622";
                font-size: 15px;
                transform: rotate(0deg);
                font-weight: 700;
            }


            &.collapsed {
                color: #373b3e;

                &::after {
                    content: "\e61a";
                }
            }

            &:focus {
                box-shadow: none;
                outline: none;
                border-color: #e1e1e1;

            }


        }

        .accordion-collapse {
            border: 0;
        }
    }

    .accordion-button {
        background: transparent;
    }
}