/**
    Theme Name: Gainioz - Charity & Donation React Template
    Author: XpressBuddy
    Author URI: https://themeforest.net/user/XpressBuddy
    Description: Gainioz - Charity & Donation React Template
    Version: 1.3
*/
/**
* [Table of contents]

## 1. Helpers

    1.1 Variables

## 2. Base

    2.1 Reset CSS
    2.2 Base or Default CSS

## 3. Layouts

    3.1 Navigation
    3.2 Header
    3.3 Hero
    3.4 About
    3.5 Features
    3.6 Review
    3.7 Footer
    3.8 Sidebar
    3.10 Portfolio
    3.11 Blog
    3.12 Sponsors
    3.13 Fact
    3.14 Volunteer
*/
/*
1.1 Variables
*/
/*-- Margin Top --*/
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Oswald:wght@200..700&display=swap");

:root {
  scroll-behavior: auto;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

/*-- Margin Bottom --*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

/*-- Margin Left --*/
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-65 {
  margin-left: 65px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-85 {
  margin-left: 85px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-115 {
  margin-left: 115px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-125 {
  margin-left: 125px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

/*-- Margin Right --*/
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-85 {
  margin-right: 85px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-95 {
  margin-right: 95px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-105 {
  margin-right: 105px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-115 {
  margin-right: 115px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-125 {
  margin-right: 125px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-135 {
  margin-right: 135px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-145 {
  margin-right: 145px;
}

.mr-150 {
  margin-right: 150px;
}

/*-- Padding Top --*/
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.ptt-20 {
  padding-top: 30px !important;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-71 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

/*-- Padding Bottom --*/
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

/*-- Padding Left --*/
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-55 {
  padding-left: 55px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-65 {
  padding-left: 65px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-75 {
  padding-left: 75px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-85 {
  padding-left: 85px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-95 {
  padding-left: 95px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-105 {
  padding-left: 105px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-115 {
  padding-left: 115px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-125 {
  padding-left: 125px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-135 {
  padding-left: 135px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-145 {
  padding-left: 145px;
}

.pl-150 {
  padding-left: 150px;
}

/*-- Padding Right --*/
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-55 {
  padding-right: 55px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-65 {
  padding-right: 65px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-75 {
  padding-right: 75px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-85 {
  padding-right: 85px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-95 {
  padding-right: 95px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-105 {
  padding-right: 105px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-115 {
  padding-right: 115px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-125 {
  padding-right: 125px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-135 {
  padding-right: 135px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-145 {
  padding-right: 145px;
}

.pr-150 {
  padding-right: 150px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-160 {
  padding-right: 160px;
}

.pr-165 {
  padding-right: 165px;
}

.pr-170 {
  padding-right: 170px;
}

.pr-175 {
  padding-right: 175px;
}

.pr-180 {
  padding-right: 180px;
}

.pr-185 {
  padding-right: 185px;
}

.pr-190 {
  padding-right: 190px;
}

.pr-195 {
  padding-right: 195px;
}

.pr-200 {
  padding-right: 200px;
}

/* 2. Base */

*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

html,
body {
  min-height: 100%;
  /* new css */
  width: 100vw !important;
  overflow-x: hidden !important;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;

}

.listtt li {
  list-style-type: disc;
}

img {
  max-width: 100%;
  height: auto;
}

i {
  font-style: normal;
}

figure {
  margin: 0;
  padding: 0;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

a {
  text-decoration: none;
}

a,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
  border: none;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

a:hover {
  text-decoration: none;
  outline: none;
  color: #eb9309;
  transition: 0.3s;
}

.linkkk:hover {
  color: #eb9309;
}

label {
  color: #666;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  outline: 0 solid;
}

/* 2.1 Reset CSS */

body {
  font-family: "Barlow", sans-serif;
  color: #666666;
  font-size: 1rem;
}

p {
  line-height: 1.75;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oswald", sans-serif;
  color: #000;
}

button {
  cursor: pointer;
}

button.button {
  background: transparent;
  border: 0px;
  transition: 0.3s;
}

::-moz-selection {
  background: #313131;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #313131;
  color: #fff;
  text-shadow: none;
}

h1 {
  font-size: calc(1.55rem + 3.6vw);
}

@media (min-width: 1200px) {
  h1 {
    font-size: 4.25rem;
  }
}

h2 {
  font-size: calc(1.16875rem + 2.125vw);
}

@media (min-width: 1200px) {
  h2 {
    font-size: 3rem;
  }
}

h3 {
  font-size: calc(1.40625rem + 1.875vw);
}

@media (min-width: 1200px) {
  h3 {
    font-size: 2.8125rem;
  }
}

h4 {
  font-size: calc(1.35625rem + 1.275vw);
}

@media (min-width: 1200px) {
  h4 {
    font-size: 2.3125rem;
  }
}

h5 {
  font-size: calc(1.31875rem + 0.825vw);
}

@media (min-width: 1200px) {
  h5 {
    font-size: 1.9375rem;
  }
}

h6 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h6 {
    font-size: 1.5rem;
  }
}

.bgPrimary {
  background: #eb9309;
}

.bgSecondary {
  background: #7fb432;
}

.shadeBg {
  background-color: #ffefec;
}

.colorSecondary {
  color: #7fb432;
}

.fontPrimary {
  font-family: "Oswald", sans-serif;
}

.fontSecondary {
  font-family: "Barlow", sans-serif;
}

.wh-100,
.hero--style5::before,
.portfolioBlock--style3::after {
  width: 100%;
  height: 100%;
}

.after-before,
.pageBreadcumb--style1::before,
.pageBreadcumb--style1::after,
.missionSection--style1::before,
.missionSection--style1::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.hidden-transition {
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.visible-transition {
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.letter--spacing05 {
  letter-spacing: 0.05em;
}

.bg-centerized {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.vertical-align-center,
.productDetails-block__rating ul li,
.productDetails-block__model,
.productDetails-block__quantity .input-group-btn,
.productDetails-block__buttons {
  display: flex;
  align-items: center;
}

/* 2.2 Base or Default CSS */

svg,
path,
.icon {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.border-top {
  border-top: 1px solid rgba(136, 146, 165, 0.25);
}

.container {
  max-width: 1314px;
}

.container--custom {
  max-width: 1644px;
}

.bbb,
.review__box {
  background: transparent;
  border: 1px solid transparent;
  box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);
}

.mask {
  -webkit-mask-image: url(../images/shapes/mask-shape1.svg);
  mask-image: url(../images/shapes/mask-shape1.svg);
}

.mask--about {
  position: absolute;
  top: 0;
  z-index: -1;
  left: -3px;
  padding: 1px;
}

@media (max-width: 1680px) {
  .mask--about {
    left: -120px;
  }
  .mask-aboutBack {
    left: -73px !important;
  }
}

@media (max-width: 1462px) {
  .mask--about {
    left: -140px;
  }
  .mask-aboutBack {
    left: -80px !important;
  }
}

@media (max-width: 1362px) {
  .mask--about {
    left: -140px;
  }
  .mask-aboutBack {
    left: -160px !important;
  }
}

@media (max-width: 1199px) {
  .mask--about {
    display: none;
  }
  .mask-aboutBack {
    display: none;
  }

}

@media (max-width: 1050px) {
  .mask--about {
    left: -26%;
  }
}

@media (max-width: 991px) {
  .mask--about {
    display: none;
  }
}

.mask--about .mask__overlay {
  position: absolute;
  left: 2px;
  top: -1px;
}

.mask--review {
  -webkit-mask-image: url(../images/shapes/testi-shape3.svg);
  mask-image: url(../images/shapes/testi-shape3.svg);
  bottom: 9%;
  right: 0;
  z-index: -1;
}

.mask--review .mask__overlay {
  position: absolute;
  left: -3px;
  top: -1px;
  transform: scale(1.007);
}

@media (max-width: 1605px) {
  .mask--review {
    display: none;
  }
}

.mask-aboutBack {
  position: absolute;
  left: 0;
  top: 66%;
  z-index: -11;
}

@media (max-width: 1368px) {
  .mask-aboutBack {
    left: -10%;
  }
}

@media (max-width: 1199px) {
  .mask-aboutBack {
    left: -30%;
  }
}

@media (max-width: 1087px) {
  .mask-aboutBack {
    display: none;
  }
}

.color-title {
  color: #0d0d0d;
}

@-webkit-keyframes toBottomFromTop {
  49% {
    transform: translateY(-100%);
  }

  50% {
    opacity: 0;
    transform: translateY(100%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes toBottomFromTop {
  49% {
    transform: translateY(-100%);
  }

  50% {
    opacity: 0;
    transform: translateY(100%);
  }

  51% {
    opacity: 1;
  }
}

.tag {
  background-color: #eb9309;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
  padding: 2.5px 11px;
}

.gx-35 {
  --bs-gutter-x: 2.188rem;
}

@media (max-width: 1666px) {
  .gx-35 {
    --bs-gutter-x: 1.5rem;
  }
}

.gy-35 {
  --bs-gutter-y: 2.188rem;
}

.gy-45 {
  --bs-gutter-y: 4rem;
}

@media (max-width: 1666px) {
  .gy-35 {
    --bs-gutter-y: 1.5rem;
  }

  .gy-45 {
    --bs-gutter-y: 2rem;
  }
}

.gy-65 {
  --bs-gutter-y: 3.188rem;
}

@media (max-width: 1666px) {
  .gy-65 {
    --bs-gutter-y: 2.5rem;
  }
}

.gx-50 {
  --bs-gutter-x: 3.125rem;
}

@media (max-width: 1666px) {
  .gx-50 {
    --bs-gutter-x: 1.5rem;
  }
}

.swiper-slide:focus-visible {
  outline: none;
}

.gray-bg {
  background: #f7f7f7;
}

.sectionButton--right {
  text-align: right;
}

@media (max-width: 1087px) {
  .sectionButton--right {
    text-align: left;
  }
}

.sectionShape {
  position: absolute;
  z-index: 1;
  display: flex;
  right: 0;
  left: 0;
  width: 100%;
}

.sectionShape--top {
  top: 0;
}

.sectionShape--bottom {
  bottom: 0;
}

.innerWrapper {
  padding: 24px;
  border: 1px solid #f4f4f4;
  background-color: #fff;
}

/* 3.8 Sidebar */

.innerWrapperSidebar {
  padding: 35px;
  border: 1px solid #f4f4f4;
  background-color: #fff;
}

@media (max-width: 1087px) {
  .innerWrapperSidebar {
    padding: 15px;
  }
}

.image-saturation {
  filter: saturate(0);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.hoverStyle:hover .image-saturation {
  transform: scale(1.1);
  filter: saturate(1);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.ourHr {
  background-color: #f1f1f1;
  opacity: 1;
}

.mb-24 {
  margin-bottom: 24px;
}

.contact--layout1 {
  padding-top: 200px;
}

/*---------------------------------------------------*/
/*	mouse cursor animation
/*---------------------------------------------------*/
.mouseCursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;
  transform: translateZ(0);
  visibility: hidden;
}

.cursor-inner {
  margin-left: -3px;
  margin-top: -3px;
  width: 6px;
  height: 6px;
  z-index: 10000001;
  background-color: #eb9309;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.cursor-inner.cursor-hover {
  margin-left: -30px;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  background-color: #eb9309;
  opacity: 0.2;
}

.cursor-outer {
  margin-left: -15px;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  border: 2px solid #eb9309;
  box-sizing: border-box;
  z-index: 10000000;
  opacity: 0.5;
  transition: all 0.08s ease-out;
}

.cursor-outer.cursor-hover {
  opacity: 0;
}

.mim_tm_all_wrap[data-magic-cursor="hide"] .mouseCursor {
  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: -1111;
}

@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 576px) {
  .progress-wrap.active-progress {
    opacity: 1;
  }
}

.progress-wrap {
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  width: 2.3rem;
  height: 2.3rem;
  cursor: pointer;
  display: block;
  border-radius: 100%;
  box-shadow: inset 0 0 0 0.1rem rgba(128, 130, 134, 0.25);
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  transform: translateY(0.75rem);
  transition: all 200ms linear, margin-right 0ms;
}

@media (prefers-reduced-motion: reduce) {
  .progress-wrap {
    transition: none;
  }
}

.progress-wrap.active-progress {
  visibility: visible;
  transform: translateY(0);
  opacity: 1;
}

.progress-wrap:after {
  position: absolute;
  content: "\f062";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  text-align: center;
  line-height: 2.3rem;
  font-size: 14px;
  color: #eb9309;
  left: 0;
  top: 0;
  height: 2.3rem;
  width: 2.3rem;
  cursor: pointer;
  display: block;
  z-index: 1;
  transition: all 200ms linear;
}

@media (prefers-reduced-motion: reduce) {
  .progress-wrap:after {
    transition: none;
  }
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #eb9309;
  stroke-width: 4;
  box-sizing: border-box;
  transition: all 200ms linear;
}

@media (prefers-reduced-motion: reduce) {
  .progress-wrap svg.progress-circle path {
    transition: none;
  }
}

.template-search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  transition: all 0.5s ease-in-out;
  transform: scaleY(0);
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
  transform-origin: 0 0 0;
}

.template-search .animation-shape-lines {
  color: #eb9309;
}

.template-search .animation-shape-lines::before,
.template-search .animation-shape-lines::after {
  background-color: #eb9309;
}

.template-search .search-form {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  width: 60%;
  margin: 0 auto;
}

@media (max-width: 1087px) {
  .template-search .search-form {
    width: 100%;
    padding: 0 15px;
  }
}

.template-search .search-form input[type="search"] {
  color: rgba(127, 180, 50, 0.7);
  font-size: 24px;
  text-align: left;
  border: 1px solid #c3c3c3;
  margin: 0 auto;
  padding: 25px 10px 25px 30px;
  outline: none;
  background-color: transparent;
  width: 100%;
}

.template-search .search-form .search-btn {
  padding: 24px 10px;
  box-shadow: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  right: 4px;
  height: 80px;
  width: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  background: #eb9309;
  color: #fff;
  border-radius: 0;
}

.template-search .search-form .search-btn i:before {
  font-size: 24px;
  font-weight: 700;
  margin-left: 0;
}

.template-search .search-form .search-btn:focus {
  box-shadow: none;
  outline: none;
}

.template-search .close {
  position: fixed;
  top: 15px;
  right: 15px;
  color: #eb9309;
  border: none;
  opacity: 1;
  visibility: visible;
  padding: 3px 15px 5px;
  font-size: 20px;
  font-weight: 300;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0 1px 2px 0px rgba(255, 255, 255, 0.75);
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: grid;
  place-content: center;
}

@media (max-width: 1087px) {
  .template-search .search-form .search-btn {
    right: 20px;
  }
}

.template-search .close:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

.template-search .close:hover {
  background-color: #eb9309;
  color: #fff;
  transform: scale(1.1);
}

.template-search.open {
  transform: scaleY(1);
  opacity: 1;
  visibility: visible;
}

.review--layout2 {
  padding-bottom: 95px;
}

.review--layout2 .swiper {
  overflow: visible;
}

#preloader {
  background: #fff;
  height: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-size: cover;
  background-position: center;
}

#preloader .loading {
  display: flex;
}

#preloader .ctn-preloader {
  padding-left: 15px;
  padding-right: 15px;
}

#preloader .spinner {
  -webkit-animation: spinner 3s infinite linear;
  animation: spinner 3s infinite linear;
  border-radius: 50%;
  border: 3px solid #f1f6f8;
  border-left-color: #eb9309;
  border-top-color: #eb9309;
  margin: 0 auto 0em auto;
  position: absolute;
  left: -40px;
  right: -40px;
  bottom: -40px;
  top: -40px;
}

#preloader .spinner:before {
  content: "";
  width: 20px;
  height: 20px;
  border: 6px solid #fff;
  box-shadow: 0 0 20px 0 rgba(4, 46, 56, 0.2);
  background: #eb9309;
  position: absolute;
  right: 31px;
  top: 41px;
  border-radius: 50%;
}

@media (max-width: 576px) {
  #preloader .spinner:before {
    top: 18px;
  }
}

#preloader .round_spinner {
  border-width: 1px;
  border-color: #eef3f4;
  border-style: solid;
  border-radius: 50%;
  background-color: #fdfdfd;
  box-shadow: 0 0 100px 0 rgba(4, 46, 56, 0.14);
  width: 248px;
  height: 248px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 40px auto 80px;
}

@media (max-width: 576px) {
  #preloader .round_spinner {
    width: 155px;
    height: 155px;
  }
}

#preloader .round_spinner h4 {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 0;
  color: white;
}

#preloader .round_spinner h4 span {
  font-weight: 700;
}

#preloader .head {
  display: block;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  font-family: "Oswald", sans-serif;
  margin: 5% 0 1% 0;
  padding: 0;
  z-index: 99;
  position: relative;
}

#preloader p {
  color: #6b707f;
  display: block;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  border: none;
  margin: 0;
  padding: 0;
}

@-webkit-keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}

/***************************

page marcup

***************************/
html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  filter: brightness(120%);
}

.main--wrapper4 {
  z-index: 2;
  position: relative;
  background-color: #fff;
}

/* ## 3. Layouts */

.mainMenu>ul {
  display: flex;
  gap: 5px;
}

@media (max-width: 1199px) {
  .mainMenu>ul {
    gap: 5px;
    justify-content: center;
  }
}

@media (min-width: 1321px) {
  .mainMenu>ul {
    gap: 10px;
    justify-content: center;
  }
}

.mainMenu>ul>li {
  position: relative;
  padding: 35px 0;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.sticky-on.sticky .mainMenu>ul>li {
  padding: 30px 0;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

/* 3.1 Navigation */

.mainMenu>ul>li>a {
  font-size: 15px;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  position: relative;
}

.mainMenu>ul>li>a::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #eb9309;
  display: inline-block;
  border-radius: 50%;
  margin-right: 2px;
  opacity: 0;
  visibility: hidden;
  transform: scale(5);
  transition: all 0.8s;
}

/* 3.2 Header */

.header__menu--style2 .mainMenu>ul>li>a {
  color: #fff;
}

.sticky-on.sticky .header__menu--style2 .mainMenu>ul>li>a {
  color: #000;
}

.mainMenu>ul>li:hover>a {
  color: #eb9309;
}

.mainMenu>ul>li:hover>a::before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: all 0.8s cubic-bezier(0.52, 1.64, 0.37, 0.66) 0s;
}

.dropdown .dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 190px;
  perspective: 1000px;
  z-index: 999;
  transform: translateY(-30px);
}

.dropdown .dropdown_menu>li {
  display: none;
  background-color: #fff;
  opacity: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.dropdown .dropdown_menu>li>a {
  color: #0d0d0d;
  font-size: 14px;
  font-weight: 500;
  display: block;
  padding: 15px 20px;
  border-top: 1px solid #f1f1f1;
}

.dropdown .dropdown_menu>li:hover>a {
  color: #eb9309;
}

.dropdown .dropdown_menu>li:first-child a {
  border-top: 0;
}

.dropdown:hover .dropdown_menu li {
  display: block;
}

.dropdown:hover .dropdown_menu li.dropdown_item-1 {
  transform-origin: top center;
  -webkit-animation: rotateX 300ms 60ms ease-in-out forwards;
  animation: rotateX 300ms 60ms ease-in-out forwards;
}

.dropdown:hover .dropdown_menu li.dropdown_item-2 {
  transform-origin: top center;
  -webkit-animation: rotateX 300ms 120ms ease-in-out forwards;
  animation: rotateX 300ms 120ms ease-in-out forwards;
}

.dropdown:hover .dropdown_menu li.dropdown_item-3 {
  transform-origin: top center;
  -webkit-animation: rotateX 300ms 180ms ease-in-out forwards;
  animation: rotateX 300ms 180ms ease-in-out forwards;
}

.dropdown:hover .dropdown_menu li.dropdown_item-4 {
  transform-origin: top center;
  -webkit-animation: rotateX 300ms 240ms ease-in-out forwards;
  animation: rotateX 300ms 240ms ease-in-out forwards;
}

.dropdown:hover .dropdown_menu li.dropdown_item-5 {
  transform-origin: top center;
  -webkit-animation: rotateX 300ms 300ms ease-in-out forwards;
  animation: rotateX 300ms 300ms ease-in-out forwards;
}

@-webkit-keyframes rotateX {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }

  50% {
    transform: rotateX(-20deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

@keyframes rotateX {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }

  50% {
    transform: rotateX(-20deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

.headerBurgerMenu__button {
  background-color: transparent;
  border: 1px solid transparent;
}

.headerBurgerMenu__button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}

.headerBurgerMenu .line {
  fill: none;
  stroke: black;
  stroke-width: 3;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.headerBurgerMenu .line1 {
  stroke-dasharray: 60 207;
  stroke-width: 3;
}

.headerBurgerMenu .line2 {
  stroke-dasharray: 40 60;
  stroke-width: 3;
}

.headerBurgerMenu .line3 {
  stroke-dasharray: 60 207;
  stroke-width: 3;
}

.headerBurgerMenu .menu-status-close .line1,
.headerBurgerMenu .sidebarBtn.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 3;
}

.headerBurgerMenu .menu-status-close .line2,
.headerBurgerMenu .sidebarBtn.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 3;
}

.headerBurgerMenu .menu-status-close .line3,
.headerBurgerMenu .sidebarBtn.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 3;
}

.offscreen-navigation .sub-menu {
  padding-left: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: none;
}

.offscreen-navigation li>a,
.offscreen-navigation p,
.offscreen-navigation ul a {
  display: block;
  position: relative;
  color: black;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.offscreen-navigation .ti-minus {
  color: #eb9309;
}

.offscreen-navigation ul .MuiCollapse-root a {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0px;
  color: #7d7d7e;
}

.offscreen-navigation ul .MuiCollapse-root {
  padding-left: 20px;
}

.offscreen-navigation p {
  margin-bottom: 0;
}

.offscreen-navigation p i {
  position: absolute;
  right: 0;
  top: 55%;
  transform: translateY(-50%);
}

.offscreen-navigation .sub-menu li>a {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 5.5px 0px;
  color: #7d7d7e;
}

.offscreen-navigation .sub-menu li>a:hover {
  color: #eb9309;
}

.offscreen-navigation .menu>li {
  padding-bottom: 8px;
}

.offscreen-navigation .menu>li a.animation.opened {
  color: #eb9309;
}

.offscreen-navigation li.menu-item-has-children>a:after {
  content: "\f067";
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  transition: 0.1s;
  right: 0;
  position: absolute;
  font-size: 13px;
  font-weight: 700;
}

.offscreen-navigation li.menu-item-has-children>a.opened:after {
  content: "\f068";
}

.cc--slideNav {
  position: fixed;
  background: #fff;
  width: 100%;
  left: 0;
  padding: 30px;
  height: 100%;
  overflow-y: auto;
  box-shadow: 0 0 10px 0 #0000001c;
  top: 0;
  z-index: 999;
  transform: translateX(-100%);
  transition: all 1s;
  max-width: 320px;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  overflow-y: auto;
  min-height: 100vh;
}

.cc--slideNav::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.cc--slideNav.show {
  transform: translateX(0%);
  display: none;
}

@media (max-width: 1232px) {
  .cc--slideNav.show {
    display: block;
  }
}

@media (max-width: 767px) {
  .cc--slideNav {
    max-width: 270px;
    padding: 20px;
  }
}

.cc-header-menu {
  padding: 21px 15px 21px;
  z-index: 99;
  background: #fff;
  display: none;
}

@media (max-width: 1232px) {
  .cc-header-menu {
    display: block;
  }
}

.mean-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 41px;
}

.mean-bar__logo {
  max-width: 200px;
}

.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  z-index: 999;
}

.header.sticky {
  -webkit-animation: smoothScroll 1s forwards;
  animation: smoothScroll 1s forwards;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-142px);
  }

  100% {
    transform: translateY(0px);
  }
}

@media (max-width: 1232px) {
  .header {
    display: none;
  }
}

.header__wrapper {
  display: flex;
  align-items: center;
  gap: 124px;
}

@media (max-width: 1666px) {
  .header__wrapper {
    gap: 40px;
  }
}

@media (max-width: 1199px) {
  .header__wrapper {
    gap: 20px;
  }
}

.header__menu {
  flex-grow: 1;
}

.header__right {
  display: flex;
  align-items: center;
  gap: 103px;
}

@media (max-width: 1666px) {
  .header__right {
    gap: 40px;
  }
}

.header__right--style2 {
  gap: 20px;
}

.header__actions ul {
  display: inline-flex;
  align-items: center;
  gap: 30px;
}

.header--styleTwo .header__actions ul {
  gap: 13px;
}

.header__actions ul li a {
  position: relative;
}

.header__actions ul li a span {
  position: absolute;
  font-size: 10px;
  height: 19px;
  width: 19px;
  background: #eb9309;
  color: #fff;
  display: grid;
  place-content: center;
  border-radius: 50%;
  right: -45%;
  top: 10%;
}

.header__actions ul li span {
  cursor: pointer;
}

.header--styleTwo .header__actions ul li a span,
.header--styleThree .header__actions ul li a span {
  right: -8px;
  top: 0;
}

.header--styleTwo .header__actions ul li a,
.header--styleThree .header__actions ul li a {
  width: 50px;
  height: 50px;
  background: #fff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);
  display: grid;
  place-content: center;
  border-radius: 50%;
}

.header--styleTwo .header__actions ul li a svg path,
.header--styleThree .header__actions ul li a svg path {
  fill: #0d0d0d;
}

.header__actions ul li:hover a svg path {
  fill: #eb9309;
}

@media (max-width: 1199px) {
  .header__actions {
    display: none;
  }
}

.header__actions--style2 ul {
  gap: 15px;
}

.header__actions--style2 ul li a {
  width: 50px;
  height: 50px;
  background: #fff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);
  display: grid;
  place-content: center;
  border-radius: 50%;
}

.header__actions--style2 ul li a svg path {
  fill: #0d0d0d;
}

.header__actions--style2 ul li a span {
  right: -8px;
  top: 0;
}

.header.sticky-on.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  box-shadow: 0px 7px 65px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  background-color: #fff;
}

@media (max-width: 1232px) {
  .header--mobile {
    display: block;
    padding-left: 15px;
  }
}

@media (max-width: 1232px) {
  .header--mobile .header__actions {
    display: block;
  }
}

.header--mobile .header__actions ul {
  display: flex;
  align-items: center;
  gap: 20px;
}

@media (max-width: 767px) {
  .header--mobile .header__actions ul {
    gap: 10px;
  }
}

.header--styleTwo .header__menu nav ul {
  justify-content: center;
}

.header--styleFour {
  /* position: relative; */
  background-color: #fff;
}

.header--styleFour.sticky-on.sticky {
  z-index: 999;
}

.header--styleFive.sticky-on.sticky .header__logo__image {
  mix-blend-mode: difference;
}

@media (max-width: 598px) {
  .footer__logo__image {
    width: 240px;
  }
}

/* 3.3 Hero */

.hero {
  position: relative;
  padding-top: 125px;
}

@media (max-width: 1232px) {
  .hero {
    padding-top: 40px;
  }
}

@media (max-width: 1087px) {
  .hero {
    padding-top: 30px;
  }
}

/* @media (max-width: 1087px) {
  .hero {
    padding-top: 55px;
  }
} */

.hero__shape {
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 1666px) {
  .hero__shape {
    display: none;
  }
}

.hero__shape--one {
  left: 50%;
  top: 28%;
}

.hero__shape--two {
  right: 43%;
  top: 76%;
}

.hero__content {
  padding-left: 66px;
}

@media (max-width: 767px) {
  .hero__content {
    padding-left: 0;
  }
}

.hero__title--small {
  color: #eb9309;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 25px;
}

.hero__title--big {
  font-size: calc(1.75rem + 6vw);
  text-transform: uppercase;
  line-height: 1.1;
  margin-bottom: 31px;
}

@media (min-width: 1200px) {
  .hero__title--big {
    font-size: 6.25rem;
  }
}

.hero__text {
  font-size: 1.25rem;
  line-height: 1.6em;
  color: #0d0d0d;
  margin-bottom: 46px;
}

.hero__figure {
  -webkit-mask-image: url(../images/shapes/shapes2.svg);
  mask-image: url(../images/shapes/shapes2.svg);
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center center;
  mask-position: center center;
  position: relative;
  margin-right: -70px;
  margin-left: 108px;
}

@media (max-width: 1666px) {
  .hero__figure {
    margin-right: 0;
  }
}

@media (max-width: 1199px) {
  .hero__figure {
    margin-left: 0;
  }
}

.hero__figure__thumbs {
  width: 100%;
}

.hero__figure__overlay {
  position: absolute;
  top: 1px;
  width: 100%;
  right: 0;
  left: 0;
}

.hero__figure--style2 {
  -webkit-mask-image: none;
  mask-image: none;
}

.hero--style2 .hero__title--small {
  justify-content: center;
  font-size: calc(1.5rem + 3vw);
  letter-spacing: 0;
  font-weight: normal;
  color: #000;
  margin-bottom: 7px;
}

@media (min-width: 1200px) {
  .hero--style2 .hero__title--small {
    font-size: 3.75rem;
  }
}

.hero--style2 .hero__title--big {
  font-size: calc(1.875rem + 2.5vw);
  font-weight: 500;
  margin-bottom: 15px;
}

@media (min-width: 1200px) {
  .hero--style2 .hero__title--big {
    font-size: 6.2rem;
  }
}

.hero--style2 .hero__content {
  padding-left: 0;
}

.hero--style2 .hero__figure {
  margin: 0;
  margin-top: -168px;
}

.hero--style2 .hero__topDown__text {
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: #808080;
  text-transform: uppercase;
  padding-top: 15px;
}

.hero--style3 {
  padding-top: 268px;
  padding-bottom: 130px;
}

@media (max-width: 1232px) {
  .hero--style3 {
    padding-top: 130px;
  }
}

.hero--style3 .hero__map {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 1666px) {
  .hero--style3 .hero__map {
    max-width: 600px;
  }
}

@media (max-width: 1199px) {
  .hero--style3 .hero__map {
    display: none;
  }
}

.hero--style3 .hero__figure {
  -webkit-mask-image: none;
  mask-image: none;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
}

@media (max-width: 1666px) {
  .hero--style3 .hero__figure {
    max-width: 600px;
  }
}

@media (max-width: 1199px) {
  .hero--style3 .hero__figure {
    position: static;
    max-width: 100%;
  }
}

.hero--style3 .hero__title--big {
  margin-bottom: 24px;
}

.hero--style3 .hero__title--small {
  font-size: calc(1.375rem + 1.5vw);
  margin-bottom: 0;
  letter-spacing: 0;
  font-weight: 400;
  color: #000;
}

@media (min-width: 1200px) {
  .hero--style3 .hero__title--small {
    font-size: 2.5rem;
  }
}

.hero--style3 .hero__text {
  margin-bottom: 36px;
}

.hero--style3 .hero__profile {
  margin-top: 0;
  padding-top: 60px;
}

.hero--style3 .hero__profile ul {
  padding: 0;
  background: transparent;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  margin-left: 0;
}

.hero--style3 .hero__instaTitle {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000;
  display: block;
  padding-top: 17px;
}

.hero--style3 .hero__instaTitle span {
  color: #eb9309;
}

.hero--style5 {
  padding-top: 267px;
  padding-bottom: 135px;
  position: relative;
  z-index: 1;
  background-position: right;
  background-attachment: fixed;
}

@media (max-width: 1232px) {
  .hero--style5 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.hero--style5::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
  -webkit-clip-path: polygon(0 0, 66% 0, 49% 100%, 0% 100%);
  clip-path: polygon(0 0, 66% 0, 49% 100%, 0% 100%);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.hero--style5:hover::before {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

/* 3.4 About */

.about {
  position: relative;
}

.about__shape {
  position: absolute;
}

.about__shape--one {
  left: 37%;
  top: 13%;
  -webkit-animation: scale-up-one 4s infinite;
  animation: scale-up-one 4s infinite;
}

@media (max-width: 1232px) {
  .about__shape--one {
    top: 22%;
  }
}

.about__shape--two {
  top: 3%;
  left: 6%;
  -webkit-animation: scale-up-one 4s infinite;
  animation: scale-up-one 4s infinite;
}

.about__shape--three {
  top: 4%;
  left: 35%;
}

.about--style3 {
  padding: 40px 0 !important;
}

.aboutArea--padding {
  padding-top: 40px;
  padding-bottom: 40px;
}

.aboutContent__text {
  padding-right: 66px;
  margin-bottom: 26px;
}

@media (max-width: 767px) {
  .aboutContent__text {
    padding-right: 0;
  }
}

.aboutContent__quote {
  display: block;
  font-size: 1.25rem;
  font-weight: 500;
  color: #0d0d0d;
  margin-bottom: 46px;
}

.aboutContent__buttons {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.aboutContent--style2 .aboutContent__text {
  padding-right: 35px;
}

.aboutContent--style3 {
  padding-left: 13px;
}

.aboutContent--style3 .aboutContent__quote {
  margin-bottom: 14px;
  font-weight: 600;
  font-size: 18px;
}

.aboutContent--style3 .aboutContent__text {
  padding-right: 0px;
  margin-bottom: 30px;
}

.aboutThumb {
  margin-right: -114px;
  position: relative;
}

@media (max-width: 1666px) {
  .aboutThumb {
    margin-right: 0;
  }
}

.aboutThumb__figure img {
  border-radius: 50%;
}

.aboutThumb__text {
  position: absolute;
  bottom: 43px;
  left: -80px;
}

.aboutThumb__text__title {
  font-size: calc(1.2625rem + 0.15vw);
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
}

@media (min-width: 1200px) {
  .aboutThumb__text__title {
    font-size: 1.375rem;
  }
}

.aboutThumb__text__year {
  display: block;
  font-size: calc(2.0625rem + 9.75vw);
  font-family: "Oswald", sans-serif;
  line-height: 1;
  -webkit-text-stroke: 2px #f1f1f1;
  -text-fill-color: #fff;
  -webkit-text-fill-color: #fff;
  font-weight: 500;
}

@media (min-width: 1200px) {
  .aboutThumb__text__year {
    font-size: 9.375rem;
  }
}

@media (max-width: 1232px) {
  .aboutThumb__text__year {
    display: none;
  }
}

.aboutThumb--style3 {
  margin-left: 164px;
}

@media (max-width: 1666px) {
  .aboutThumb--style3 {
    margin-left: 0;
  }
}

.joinContent {
  text-align: right;
  padding-left: 81px;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (max-width: 1087px) {
  .joinContent {
    padding-left: 0;
  }
}

.joinContent__text {
  margin-bottom: 35px;
}

.joinSectionThumb {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  -webkit-mask-image: url(../images/shapes/joinmaskshape.png);
  mask-image: url(../images/shapes/joinmaskshape.png);
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
}

@media (max-width: 1666px) {
  .joinSectionThumb {
    max-width: 811px;
  }
}

@media (max-width: 1430px) {
  .joinSectionThumb {
    max-width: 690px;
    height: 80%;
  }
}

@media (max-width: 1200px) {
  .joinSectionThumb {
    max-width: 570px;
    height: 80%;
  }
}

@media (max-width: 1100px) {
  .joinSectionThumb {
    max-width: 500px;
    height: 80%;
  }
}

@media (max-width: 1066px) {
  .joinSectionThumb {
    max-width: 450px;
    height: 80%;
  }
}

.justify-content-last {
  justify-content: end;
}

@media (max-width: 991px) {
  .joinSectionThumb {
    display: none;
  }

  .section-to-left {
    text-align: left;
    justify-content: flex-start;
  }
}

.joinSectionThumb img {
  height: 100%;
}

.aboutThumb3 {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-mask-image: url(../images/shapes/about-mask-shape-h3.png);
  mask-image: url(../images/shapes/about-mask-shape-h3.png);
}

@media (max-width: 1087px) {
  .aboutThumb3 {
    display: none;
  }
}

.aboutDetailsText,
.aboutDetailsText ul li h6,
.aboutDetailsText ul li p {
  font-size: 20px;
}

.aboutDetailsThumb {
  border-radius: 50%;
  overflow: hidden;
}

.aboutDetailsThumb--big {
  margin: 0 -80px;
  z-index: 1;
  position: relative;
}

@media (max-width: 1199px) {
  .aboutDetailsThumb--big {
    margin: -80px 0;
  }
}

@media (max-width: 1199px) {
  .aboutDetailsThumb--big {
    margin: 0px 0;
  }
}

@media (max-width: 1199px) {
  .aboutDetailsThumb img {
    width: 100%;
  }
}

/* @media (max-width: 1199px) {
  .responsive-images img {
    width:48%;
  }
} */

img.map-about-tab {
  position: absolute;
  right: 0;
  left: 0;
  z-index: -1;
}

/* 3.5 Features */

.featureArea__map {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: center;
  z-index: -1;
  opacity: 0.05;
}

.keyFeatureBox {
  background: #fff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);
  padding: 45px 45px 15px 45px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .keyFeatureBox {
    background: transparent;
    border: 0;
    box-shadow: none;
    padding: 0;
  }
}

.feature--bg {
  background: #f7f7f7;
}

.keyFeatureBlock {
  background: #fff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 36px 25px 36px 0;
  margin-left: 41px;
  margin-bottom: 20px !important;
  position: relative;
}

.keyFeatureBlock__text {
  margin-bottom: 0;
}


.keyFeatureBlock__icon {
  width: 100px;
  height: 100px;
  display: grid;
  place-content: center;
  background: #fff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);
  border-radius: 50%;
  margin-left: -41px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.keyFeatureBlock:hover .keyFeatureBlock__icon {
  border-color: #eb9309;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.keyFeatureBlock__content {
  padding-left: 30px;
}

@media (max-width: 767px) {
  .keyFeatureBlock__content {
    padding-left: 20px;
  }
}

.keyFeatureBlock__heading {
  font-size: calc(1.2625rem + 0.15vw);
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 8px;
}

@media (min-width: 1200px) {
  .keyFeatureBlock__heading {
    font-size: 1.375rem;
  }
}

.keyFeatureBlock__skill {
  position: absolute;
  height: 10px;
  bottom: -5px;
}

.keyFeatureBlock__skill::after {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background: #ffffff;
  border: 2px solid #eb9309;
  border-radius: 100%;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.keyFeatureBlock__skill__bar {
  background: #eb9309;
  height: 2px;
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.keyFeatureBlock--style2,
.keyFeatureBlock--style3 {
  flex-direction: column;
  align-items: start;
  margin-left: 0;
  padding: 40px 37px;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  gap: 25px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.keyFeatureBlock--style2 .keyFeatureBlock__icon,
.keyFeatureBlock--style3 .keyFeatureBlock__icon {
  margin-left: 0;
  width: unset;
  height: unset;
  border: 0;
}

.keyFeatureBlock--style2 .keyFeatureBlock__content,
.keyFeatureBlock--style3 .keyFeatureBlock__content {
  padding-left: 0;
}

.keyFeatureBlock--style2 .keyFeatureBlock__heading,
.keyFeatureBlock--style3 .keyFeatureBlock__heading {
  margin-bottom: 13px;
}

.keyFeatureBlock--style2 .keyFeatureBlock__text,
.keyFeatureBlock--style3 .keyFeatureBlock__text {
  margin-bottom: 13px;
}

.keyFeatureBlock--style2 .keyFeatureBlock__link,
.keyFeatureBlock--style3 .keyFeatureBlock__link {
  display: inline-flex;
  gap: 6px;
}

.keyFeatureBlock--style2 .keyFeatureBlock__link__dot,
.keyFeatureBlock--style3 .keyFeatureBlock__link__dot {
  width: 8px;
  height: 8px;
  display: inline-block;
  background: #e6e6e6;
  border-radius: 50%;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.keyFeatureBlock--style2 .keyFeatureBlock__link:hover svg,
.keyFeatureBlock--style3 .keyFeatureBlock__link:hover svg {
  transform: translateX(10px);
}

.keyFeatureBlock--style2 .keyFeatureBlock__link:hover svg path,
.keyFeatureBlock--style3 .keyFeatureBlock__link:hover svg path {
  fill: #eb9309;
}

.keyFeatureBlock--style2:hover,
.keyFeatureBlock--style3:hover {
  background: #fff;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.keyFeatureBlock--style2:hover .keyFeatureBlock__link__dot,
.keyFeatureBlock--style3:hover .keyFeatureBlock__link__dot {
  background: #eb9309;
}

.keyFeatureBlock--style3 {
  gap: 35px;
  border: 0;
  padding: 40px 33px;
  border-radius: 0;
}

.keyFeatureBlock--style3 .keyFeatureBlock__icon {
  position: relative;
}

.keyFeatureBlock--style3 .keyFeatureBlock__icon::before {
  position: absolute;
  content: "";
  /* width: 55px;
  height: 55px; */
  background-color: #f7f7f7;
  border-radius: 50%;
  top: 0;
  left: 16px;
}

.keyFeatureBlock--style3 .keyFeatureBlock__icon img {
  position: relative;
  z-index: 1;
}

.keyFeatureBlock--style3 .keyFeatureBlock__heading {
  margin-bottom: 25px;
}

.keyFeatureBlock--style3 .keyFeatureBlock__text {
  font-size: 18px;
  margin-bottom: 43px;
}

.keyFeatureBlock--style3:hover {
  border: 0;
  box-shadow: none;
}

.keyFeatureBlock--style4 {
  flex-direction: column;
  margin-left: 0;
  align-items: self-start;
  padding: 0 29px 35px 37px;
}

.keyFeatureBlock--style4 .keyFeatureBlock__icon {
  margin-left: -7px;
  margin-top: -41px;
  margin-bottom: 20px;
}

.keyFeatureBlock--style4 .keyFeatureBlock__content {
  padding-left: 0;
}

.keyFeatureBlock--style4 .keyFeatureBlock__heading {
  margin-bottom: 13px;
}

.keyFeatureBlock--style5 {
  padding: 0px 37px 0px 0px;
  gap: 20px;
}

@media (max-width: 1199px) {
  .keyFeatureBlock--style5 {
    padding: 0;
  }
}

.keyFeatureBlock--style5 .keyFeatureBlock__text {
  margin-bottom: 15px;
}

.keyFeatureBlock--style5 .keyFeatureBlock__link {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: #0d0d0d;
  align-items: center;
}

.keyFeatureBlock--style5 .keyFeatureBlock__link:hover {
  color: #eb9309;
  letter-spacing: 0.1em;
}

.keyFeatureBlock--style5 .keyFeatureBlock__heading {
  margin-bottom: 14px;
}

.keyFeatureBlock--style5 .keyFeatureBlock__icon::before {
  width: 36px;
  height: 36px;
  background-color: rgba(235, 147, 9, 0.1);
  left: -15px;
  bottom: 5px;
  top: auto;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.keyFeatureBlock--style5:hover .keyFeatureBlock__icon::before {
  transform: scale(1.3);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.donnerAreaContent__bigTitle {
  font-size: calc(2.625rem + 16.5vw);
  line-height: 1;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .donnerAreaContent__bigTitle {
    font-size: 15rem;
  }
}

@media (max-width: 440px) {
  .donnerAreaContent__bigTitle {
    font-size: calc(1.6rem + 16.5vw);
  }
}

.donnerAreaContent__bigTitle__number {
  -webkit-text-stroke: 4px #eb9309;
  -webkit-text-fill-color: #000;
}

@media (max-width: 1087px) {
  .donnerAreaContent__bigTitle__number {
    -webkit-text-stroke: 2px #eb9309;
  }
}

.donnerAreaContent__bigTitle__text {
  color: #eb9309;
}

.donnerAreaContent__heading {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 500;
  margin-bottom: 33px;
}

@media (min-width: 1200px) {
  .donnerAreaContent__heading {
    font-size: 2.5rem;
  }
}

.g-15 {
  --bs-gutter-x: 0.75rem;
  --bs-gutter-y: 0.75rem;
}

.featureBlock__wrap,
.featureBlock__donation {
  background: #fff;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);
  padding: 10px;
  margin-bottom: 15px;
}

.featureBlock__thumb {
  margin-bottom: 19px;
  position: relative;
}

.featureBlock__thumb img {
  width: 100%;
}

.featureBlock__hashLink {
  position: absolute;
  left: 0;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  background-image: url(../images/shapes/featuredCateShape1.png);
  min-width: 152px;
  min-height: 64px;
  background-repeat: no-repeat;
  padding: 9px 11px;
  top: 0;
}

.featureBlock__content {
  padding: 0 15px 5px 15px;
}

.featureBlock__heading {
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.5em;
  margin-bottom: 12px;
  cursor: pointer;
  padding-right: 50px;
}

@media (max-width: 1199px) {
  .featureBlock__heading {
    padding-right: 0;
  }
}

.featureBlock__heading__link {
  display: inline;
  padding-bottom: 0;
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: linear-gradient(to bottom, #000 0%, #000 98%);
  background-size: 0 1px;
  background-repeat: no-repeat;
  background-position: left 100%;
}

.featureBlock__donation {
  padding: 20px 25px 20px;
}

.featureBlock__donation__progress {
  margin-bottom: 25px;
}

.featureBlock__donation__text {
  display: block;
  text-align: right;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: #000;
  transform: translateX(3px);
  margin-bottom: 4px;
}

.featureBlock__donation__line {
  position: relative;
  display: block;
}

.featureBlock__donation__line .skill-bars {
  height: 16px;
  width: 100%;
  display: block;
  background-color: transparent;
}

.featureBlock__donation__line .skill-bars::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 4px;
  background: #f2f2f2;
  top: 50%;
  transform: translateY(-50%);
}

.featureBlock__donation__line .skill-bars__line {
  position: absolute;
  height: 16px;
  width: 100%;
  display: block;
  background-color: transparent;
  top: 50%;
  transform: translateY(-50%);
}

.featureBlock__donation__line .skill-bars__line::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 4px;
  background: #eb9309;
  top: 50%;
  transform: translateY(-50%);
}

.featureBlock__donation__line .skill-bars__line::after {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  border: 4px solid #eb9309;
  border-radius: 100%;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  left: auto;
  right: 0;
}

.featureBlock__eqn {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.featureBlock__eqn__block {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
}

.featureBlock__eqn__title {
  font-size: 11px;
  font-weight: 600;
  color: #666666;
  display: block;
  margin-bottom: 2px;
}

.featureBlock__eqn__price {
  font-size: 0.9375rem;
  font-weight: 600;
  color: #0d0d0d;
}

.featureBlock:hover .featureBlock__heading__link,
.featureBlock--active .featureBlock__heading__link {
  background-size: 100% 1px;
  color: #000;
}

.featureBlock:hover .featureBlock__hashLink,
.featureBlock--active .featureBlock__hashLink {
  background-image: url(../images/shapes/featuredCateShape2.png);
  color: #fff;
}

.mvvTabs__wrapper {
  gap: 70px;
}

@media (max-width: 1199px) {
  .mvvTabs__wrapper {
    gap: 30px;
  }
}

@media (max-width: 1087px) {
  .mvvTabs__wrapper {
    flex-wrap: wrap;
  }
}

.mvvTabs__wrapper .nav {
  gap: 30px;
  flex-direction: column;
  border: 0;
}

@media (max-width: 1087px) {
  .mvvTabs__wrapper .nav {
    flex-direction: row;
    flex: 1;
  }
}

@media (max-width: 450px) {
  .mvvTabs__wrapper .nav {
    flex-direction: column;
    text-align: center;
  }
}

.mvvTabs__wrapper .tab-content {
  border: 1px solid #f1f1f1;
  padding: 69px 130px 69px 80px;
  height: 100%;
  background-color: #fff;
  width: 100%;
}

@media (max-width: 1199px) {
  .mvvTabs__wrapper .tab-content {
    padding: 30px 55px 69px 55px;
  }
}

@media (max-width: 767px) {
  .mvvTabs__wrapper .tab-content {
    padding: 30px 15px 30px 15px;
  }
}

.mvvTabs .nav-link {
  font-size: calc(1.275rem + 0.3vw);
  color: #a1a1a1;
  text-transform: uppercase;
  background: #fff;
  border: 1px solid #f1f1f1;
  font-weight: 500;
  font-family: "Oswald", sans-serif;
  padding: 55.5px 35px;
  position: relative;
  min-width: 309px;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .mvvTabs .nav-link {
    font-size: 1.5rem;
  }
}

@media (max-width: 1087px) {
  .mvvTabs .nav-link {
    padding: 23.5px 35px;
    min-width: auto;
  }
}

@media (max-width: 767px) {
  .mvvTabs .nav-link {
    width: 100%;
  }
}

.mvvTabs .nav-link::before {
  position: absolute;
  content: "";
  height: 0%;
  width: 4px;
  background-color: #eb9309;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.mvvTabs .nav-link.nav-link.active {
  color: #0d0d0d;
  background: #fff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);
}

.mvvTabs .nav-link.nav-link.active::before {
  opacity: 1;
  visibility: visible;
  height: 100%;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.mvvTabs__heading {
  font-size: calc(1.46875rem + 2.625vw);
  text-transform: uppercase;
  margin-bottom: 33px;
}

@media (min-width: 1200px) {
  .mvvTabs__heading {
    font-size: 3.4375rem;
  }
}

.mvvTabs__skills {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mvvTabs__skills__counter {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 3px;
}

@media (min-width: 1200px) {
  .mvvTabs__skills__counter {
    font-size: 1.5rem;
  }
}

.mvvTabs__skills__title {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.featureTab {
  position: relative;
  z-index: 1;
}

.featureTab .nav-tabs {
  justify-content: center;
  border-bottom: 0;
  gap: 45px;
}

@media (max-width: 1087px) {
  .featureTab .nav-tabs {
    gap: 20px;
  }
}

.featureTab .nav-tabs .nav-link {
  background: #fdfdfd;
  border: 1px solid #f1f1f1;
  border-radius: 143px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #a2a2a2;
  padding: 37px 76px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  cursor: pointer;
}

@media (max-width: 1087px) {
  .featureTab .nav-tabs .nav-link {
    padding: 20px 30px;
  }
}

.featureTab .nav-tabs .nav-link.active {
  color: #0d0d0d;
  background: #fff;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.featureTab__content {
  padding-top: 93px;
}

.featureTab__content .featureBlock__donation__progress {
  margin-bottom: 94px;
}

.featureTab__content__counter {
  font-weight: 500;
  font-size: calc(2.375rem + 13.5vw);
  text-align: center;
  margin-bottom: 27px;
}

@media (min-width: 1200px) {
  .featureTab__content__counter {
    font-size: 12.5rem;
  }
}

.featureTab__content .skill-bars::before {
  height: 15px;
  border-radius: 90px;
}

.featureTab__content .skill-bars .skill-bar::before {
  height: 15px;
  border-radius: 90px;
}

.featureTab__content .skill-bars .skill-bar::after {
  width: 53px;
  height: 53px;
  border-width: 15px;
}

.featureTab__content .skill-bar--text {
  transform: translateX(7.5px);
  margin-bottom: 28px;
}

.featureTab__content .skill-bar--text span {
  width: 68px;
  height: 27px;
  display: inline-grid;
  place-content: center;
  background: #fff;
  border: 1px solid #0d0d0d;
  box-sizing: border-box;
  box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);
  border-radius: 2px;
  position: relative;
}

.featureTab__content .skill-bar--text span::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  border-color: #0d0d0d transparent transparent transparent;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
}

.featureTab--style2 .nav-tabs {
  gap: 25px;
}

@media (max-width: 1087px) {
  .featureTab--style2 .nav-tabs {
    gap: 10px;
  }
}

.featureTab--style2 ul {
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

@media (max-width: 1087px) {
  .featureTab--style2 ul {
    max-width: 600px;
    justify-content: center;
  }
}

.featureTab--style2 li {
  background: #fdfdfd;
  border: 1px solid #f1f1f1;
  border-radius: 143px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #a2a2a2;
  padding: 20px 40px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  cursor: pointer;
}

@media (max-width: 1087px) {
  .featureTab--style2 li {
    margin: 5px;
  }
}

.featureTab--style2 li.active {
  color: #000;
}

@media (max-width: 1087px) {
  .featureTab--style2 li {
    font-size: 0.875rem;
    padding: 7px 20px;
  }
}

.about-page .fact .fact__map {
  display: none;
}

.about-page .volunteer {
  display: none;
}

/* 3.6 Review */

.review__box {
  position: relative;
}

@media (max-width: 767px) {
  .review__box__images {
    display: none;
  }
}

.review__box__images li {
  position: absolute;
}

/* .review__box__images li>img {
  opacity: 0.6;
} */

.review__box__images li:nth-child(1) {
  bottom: 22%;
  left: 0%;
  -webkit-animation: scale-up-one 5s linear infinite;
  animation: scale-up-one 5s linear infinite;
}

.review__box__images li:nth-child(2) {
  top: 20%;
  left: -5%;
  animation: scale-up-one 8s linear infinite reverse;
}

@media (max-width: 1400px) {
  .review__box__images li:nth-child(2) {
    left: -2%;
  }
}

.review__box__images li:nth-child(3) {
  bottom: 97%;
  left: 9px;
  -webkit-animation: scale-up-one 10s linear infinite;
  animation: scale-up-one 10s linear infinite;
}

.review__box__images li:nth-child(4) {
  top: -10%;
  left: 18%;
  animation: scale-up-one 12s linear infinite reverse;
}

.review__shape {
  position: absolute;
}

.review__shape--one {
  right: 0;
  bottom: 35%;
  z-index: -1;
}

.review__shape--two {
  right: 0;
  bottom: -4%;
  z-index: -1;
}

@media (max-width: 954px) {
  .review__shape--two {
    display: none;
  }
}

.reviewblock {
  max-width: 770px;
  margin: 0 auto;
  position: relative;
  padding: 40px 15px 40px;
}

@media (max-width: 767px) {
  .reviewblock {
    padding: 45px 15px 65px;
  }
}

.reviewblock__content {
  position: relative;
  z-index: 1;
}

.reviewblock__author__image {
  border-radius: 50%;
  background-color: #fff;
  border: 4px solid #fff;
  margin-bottom: 33px;
}

.reviewblock__author__name {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #0d0d0d;
}

.reviewblock__quoteIcon__one {
  display: block;
  margin-bottom: 30px;
}

.reviewblock__quoteIcon__two {
  position: absolute;
  line-height: 0;
  right: 0;
  left: 0;
  top: 50%;
  transform: translateY(-30%);
}

@media (max-width: 767px) {
  .reviewblock__quoteIcon__two {
    transform: translateY(-50%);
  }
}

@media (max-width: 767px) {
  .reviewblock__quoteIcon__two>svg {
    width: 80%;
  }
}

.reviewblock__qotes {
  font-size: calc(1.3125rem + 0.75vw);
  line-height: 1.4em;
  font-weight: 400;
  margin-bottom: 35px;
}

@media (min-width: 1200px) {
  .reviewblock__qotes {
    font-size: 1.875rem;
  }
}

@media (max-width: 216px) {
  .reviewblock__qotes {
    margin-top: 280px;
  }
}

@media (min-width: 216px) and (max-width: 256px) {
  .reviewblock__qotes {
    margin-top: 220px;
  }
}

@media (min-width: 256px) and (max-width: 300px) {
  .reviewblock__qotes {
    margin-top: 160px;
  }
}

@media (min-width: 300px) and (max-width: 370px) {
  .reviewblock__qotes {
    margin-top: 100px;
  }
}

.reviewblock--style2 {
  padding: 0;
  margin-bottom: 50px;
}

.reviewblock--style2 .reviewblock__authorImage {
  margin-bottom: 26px;
}

.reviewblock--style2 .reviewblock__authorImage img {
  border-radius: 50%;
}

.reviewblock--style2 .reviewblock__authorName {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 22px;
}

.reviewblock--style2 .reviewblock__authorSpeech {
  font-size: 0.9375rem;
}

.reviewblock--style2 .reviewblock__authorDes {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.reviewblock--style3 {
  padding: 0px 130px 95px;
}

@media (max-width: 1087px) {
  .reviewblock--style3 {
    padding: 0px 15px 20px;
  }
}

.reviewblock--style3 .reviewblock__content {
  margin-top: -55px;
}

.reviewblock--style3 .reviewblock__qotes {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  .reviewblock--style3 .reviewblock__qotes {
    font-size: 1.5rem;
  }
}

.reviewblock--style3 .reviewblock__author__image {
  border-color: #eb9309;
  border-width: 2px;
}

/* 3.7 Footer */

.footer {
  position: relative;
}

.footer__shape {
  position: absolute;
  top: -1px;
  width: 100%;
}

.footer--bg {
  background: #0d0d0d;
}

.footer__social {
  text-align: right;
  margin-bottom: 20px;
}

.footer__logo {
  margin-bottom: 20px;
}

.footer__line {
  margin: 0;
  opacity: 1;
  background-color: #303030;
}

.footer__heading {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 25px;
}

.footer__menu ul li {
  margin-bottom: 10px;
}

.footer__menu ul li a {
  font-size: 15px;
  color: #fff;
  display: block;
}

.footer__menu ul li:last-child {
  margin-bottom: 0;
}

.footer__menu ul li:hover a {
  color: #eb9309;
}

.footer__newsletter__formGroup {
  display: flex;
  position: relative;
  align-items: center;
}

.footer__newsletter__input {
  flex: 0 0 auto;
  width: 100%;
  height: 55px;
  background-color: #303030;
  border: 0;
  padding: 0 17px;
  color: #a1a1a1;
}

.footer__newsletter__input:focus-visible,
.footer__newsletter__input:focus,
.footer__newsletter__input:hover {
  outline: none;
}

.footer__newsletter__input::-moz-placeholder {
  color: #a1a1a1;
}

.footer__newsletter__input:-ms-input-placeholder {
  color: #a1a1a1;
}

.footer__newsletter__input::placeholder {
  color: #a1a1a1;
}

.footer__newsletter__button {
  position: absolute;
  right: 0;
  height: 100%;
  background-color: #eb9309;
  text-transform: uppercase;
  border: 0;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.05em;
  padding: 0 30px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.footer__newsletter__button:hover {
  background-color: #7fb432;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.footer__newsletter__check,
.footer__newsletter__check[type="checkbox"] {
  background-color: #484848;
  border-radius: 0;
  margin-right: 11px;
  margin-top: 0px;
}

.footer__newsletter__label {
  color: #a1a1a1;
}

.footer__copyright__text {
  color: #fff;
  font-size: 0.9375rem;
}

.footer-main {
  background-color: #0d0d0d;
}

.footer-list {
  color: var(--bs-light);
}

.footer-list li {
  padding: 6px 0;
}

.footer-list a {
  color: var(--color-secondary);
  text-decoration: none;
}

.footer-list a:hover,
.footer-list a:focus {
  color: var(--bs-light);
  text-decoration: none;
}

.footer-copyright {
  background-color: #000;
  color: #fff;
  padding: 25px 0;
  text-align: center;
}

.footer-copyright a {
  color: #eb9309;
}

.heading--tertiary {
  font-size: 20px;
  font-weight: 700;
  color: #eb9309;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.footer-nav__list {
  display: flex;
}

.footer-nav__link {
  padding: 10px 15px;
  color: #fff;
}

.footer-section {
  position: -webkit-sticky !important;
  position: sticky !important;
  bottom: 0;
  z-index: 1;
}

.sidebarLayout {
  position: -webkit-sticky;
  position: sticky;
  top: 134px;
}

.sidebarTitle__heading {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 500;
}

@media (min-width: 1200px) {
  .sidebarTitle__heading {
    font-size: 1.5rem;
  }
}

.sidebarCategories ul {
  display: grid;
  gap: 15px;
}

.sidebarCategories ul li a {
  display: flex;
  justify-content: space-between;
  border: 1px solid #f4f4f4;
  padding: 11px 17px;
  color: #666666;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  font-weight: 500;
}

.sidebarCategories ul li a:hover {
  border-color: #e4e4e4;
  box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);
  color: #eb9309;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.sidebarContacts {
  display: flex;
  gap: 35px;
  flex-direction: column;
}

.sidebarContacts input,
.sidebarContacts textarea {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #f6f3ee;
  resize: none;
  padding-bottom: 8px;
}

.sidebarContacts .btn {
  align-self: flex-start;
}

.searchInput {
  position: relative;
}

.searchInput__box {
  width: 100%;
  min-height: 46px;
  border: 1px solid #f7f7f7;
  font-size: 14px;
  color: #666666;
  padding: 0 19px;
}

.searchInput__box::-moz-placeholder {
  font-size: 14px;
  color: #666666;
}

.searchInput__box:-ms-input-placeholder {
  font-size: 14px;
  color: #666666;
}

.searchInput__box::placeholder {
  font-size: 14px;
  color: #666666;
}

.searchInput__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  border: 0;
  background-color: transparent;
}

/* 3.11 Blog */

.sidebarBlogs ul {
  display: grid;
  gap: 20px;
}

.blogBlockSmall {
  display: flex;
  align-items: center;
  gap: 24px;
}

.blogBlockSmall__thumb {
  overflow: hidden;
}

.blogBlockSmall__thumb__link {
  display: inline-block;
}

.blogBlockSmall__thumb__link img {
  filter: saturate(0);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  max-width: 140px;
  min-height: 80px;
  object-fit: cover;
}

.blogBlockSmall__content {
  flex: 1;
}

.blogBlockSmall__heading {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
  letter-spacing: 0.06em;
}

.blogBlockSmall__meta {
  font-size: 0.875rem;
  display: block;
  margin-bottom: 7px;
}

.blogBlockSmall:hover .blogBlockSmall__thumb__link img {
  filter: saturate(1);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transform: scale(1.1);
}

.blogBlockSmall--style2 .blogBlockSmall__heading {
  font-family: "Barlow", sans-serif;
  letter-spacing: 0;
  font-size: 1.125rem;
  color: #666666;
  font-weight: 500;
}

.blogBlockSmall--style2 .blogBlockSmall__heading a {
  display: inline;
  padding-bottom: 0;
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: linear-gradient(to bottom, #000 0%, #000 98%);
  background-size: 0 1px;
  background-repeat: no-repeat;
  background-position: left 100%;
}

.blogBlockSmall--style2:hover .blogBlockSmall__heading a {
  background-size: 100% 1px;
  color: #000;
}

.sidebarContact {
  padding-right: 35%;
}

.sidebarContact ul {
  display: grid;
  gap: 18px;
}

.sidebarContact ul li {
  color: #666666;
}

.sidebarContact ul li span {
  font-weight: 600;
  font-size: 15px;
  color: #0d0d0d;
}

.sidebarTags ul,
.blogDetails-tags ul {
  display: flex;
  flex-wrap: wrap;
  gap: 11px;
}

.sidebarTags ul li a,
.blogDetails-tags ul li a {
  text-transform: uppercase;
  color: #a0a0a0;
  text-transform: uppercase;
  border: 1px solid #f6f3ee;
  display: inline-block;
  font-weight: 600;
  border-radius: 4px;
  padding: 11px 29px;
  font-size: 12px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.sidebarTags ul li:hover a,
.blogDetails-tags ul li:hover a {
  background-color: #eb9309;
  border-color: #eb9309;
  color: #fff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

/* 3.10 Portfolio */

.portfolioBlock {
  overflow: hidden;
}

.portfolioBlock__figure__shape {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translateY(40px);
}

.portfolioBlock__figure__shape>img {
  width: 100%;
  min-height: 285px;
}

.portfolioBlock__figure__thumb {
  width: 100%;
}

.portfolioBlock__content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 15px 30px 15px;
  opacity: 0;
  visibility: hidden;
  transition: all 1.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 2;
}

.portfolioBlock__heading {
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 500;
  color: #fff;
  padding-bottom: 16px;
  overflow: hidden;
  transition: all 1.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@media (min-width: 1200px) {
  .portfolioBlock__heading {
    font-size: 2rem;
  }
}

.portfolioBlock__heading>a {
  display: inline;
  padding-bottom: 0;
  transition: all 1.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: linear-gradient(to bottom, #fff 0%, #fff 98%);
  background-size: 0 1px;
  background-repeat: no-repeat;
  background-position: left 100%;
  transform: translateY(-50px);
}

.portfolioBlock__heading:hover>a {
  color: #fff;
}

.portfolioBlock__hashLink {
  color: #fff;
  display: block;
  font-weight: 600;
  text-transform: uppercase;
  overflow: hidden;
  transition: all 1.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.portfolioBlock__hashLink>span {
  display: block;
  transform: translateY(50px);
  transition: all 1.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.portfolioBlock__hashLink:hover {
  color: #fff;
}

.portfolioBlock__hashLink:hover span {
  -webkit-animation: toBottomFromTop 0.6s forwards;
  animation: toBottomFromTop 0.6s forwards;
}

.portfolioBlock:hover .portfolioBlock__content,
.portfolioBlock--active .portfolioBlock__content {
  opacity: 1;
  visibility: visible;
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.portfolioBlock:hover .portfolioBlock__figure__shape,
.portfolioBlock--active .portfolioBlock__figure__shape {
  opacity: 1;
  visibility: visible;
  transition: 1.5s cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transform: translateY(0px);
}

.portfolioBlock:hover .portfolioBlock__heading>a,
.portfolioBlock--active .portfolioBlock__heading>a {
  background-size: 100% 1px;
  color: #fff;
  transform: translateY(0px);
  transition: all 1.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.portfolioBlock:hover .portfolioBlock__hashLink>span,
.portfolioBlock--active .portfolioBlock__hashLink>span {
  transform: translateY(0px);
  transition: all 1.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.portfolioBlock--style2 .portfolioBlock__more {
  position: absolute;
  right: 0;
  left: 0;
  width: 81px;
  height: 81px;
  margin: 0 auto;
  display: grid;
  place-content: center;
  background: #0d0d0d;
  border-radius: 50%;
  border: 2px solid #fff;
  top: -40.5px;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  transition: all 2s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.portfolioBlock--style2 .portfolioBlock__more:hover {
  background: #fff;
}

.portfolioBlock--style2 .portfolioBlock__more:hover svg path {
  fill: #eb9309;
}

.portfolioBlock--style2 .portfolioBlock__content {
  padding: 0 15px 80px 15px;
}

.portfolioBlock--style2:hover .portfolioBlock__more,
.portfolioBlock--style2.portfolioBlock--active .portfolioBlock__more {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: all 2s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.portfolioBlock--style3 {
  z-index: 1;
}

.portfolioBlock--style3 .portfolioBlock__heading {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  .portfolioBlock--style3 .portfolioBlock__heading {
    font-size: 1.75rem;
  }
}

.portfolioBlock--style3::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  transform-origin: bottom;
  transform: scaleY(0);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.portfolioBlock--style3:hover::after,
.portfolioBlock--style3.portfolioBlock--active::after {
  transform: scaleY(1);
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.portfolioBlock--style3:hover .portfolioBlock__figure__thumb,
.portfolioBlock--style3.portfolioBlock--active .portfolioBlock__figure__thumb {
  filter: grayscale(1);
}

.portfolioBlock--style3:hover .portfolioBlock__hashLink,
.portfolioBlock--style3.portfolioBlock--active .portfolioBlock__hashLink {
  color: #eb9309;
}

.blogBlock__content {
  background: #fff;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);
}

.blogBlock__heading {
  font-size: calc(1.3125rem + 0.75vw);
  font-weight: 500;
}

@media (min-width: 1200px) {
  .blogBlock__heading {
    font-size: 1.875rem;
  }
}

.blogBlock__meta__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 84%;
  flex-wrap: wrap;
  gap: 5px;
}

@media (max-width: 1199px) {
  .blogBlock__meta__list {
    max-width: 100%;
  }
}

.blogBlock__metaUser {
  display: inline-flex;
  align-items: center;
  gap: 11px;
}

.blogBlock__metaUser__thumb {
  max-width: 36px;
  border-radius: 50%;
}

.blogBlock__metaUser__name {
  font-size: 1rem;
  font-weight: 600;
  color: #191b1e;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.blogBlock__metaUser:hover .blogBlock__metaUser__name {
  color: #eb9309;
}

.blogBlock__reactButton {
  background-color: transparent;
  border: 0;
}

.blogBlock__reactButton__count {
  color: #a8a8a8;
}

.blogBlock__reactButton__count::after {
  position: relative;
  content: "\f004";
  font-family: "Font Awesome 6 Free";
  padding-left: 7px;
  color: #0d0d0d;
  font-size: 15px;
  font-weight: 400;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.blogBlock__reactButton:hover .blogBlock__reactButton__count::after {
  font-weight: 900;
  color: #f67575;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.blogBlock__date {
  display: flex;
  align-items: center;
  gap: 7px;
}

.blogBlock__date__text {
  color: #a8a8a8;
}

.blogBlock__date i {
  color: #0d0d0d;
  font-size: 15px;
}

.blogBlock--style1 .blogBlock__content {
  padding: 70px 80px;
  max-width: 702px;
  margin: -13.6% auto 0 auto;
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .blogBlock--style1 .blogBlock__content {
    padding: 20px 15px;
    max-width: 702px;
    margin: -7.6% 15px 0 15px;
  }
}

.blogBlock--style2 .blogBlock__content {
  padding: 40px 40px;
  max-width: 100%;
  margin: -13.6% 25px 0 25px;
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .blogBlock--style2 .blogBlock__content {
    padding: 20px 15px;
    max-width: 702px;
    margin: -7.6% 15px 0 15px;
  }
}

.blogBlock--style3 .blogBlock__content {
  padding: 40px 40px;
}

@media (max-width: 1087px) {
  .blogBlock--style3 .blogBlock__content {
    padding: 20px 20px;
  }
}

.blogBlock--style4 .blogBlock__header {
  padding-right: 20%;
}

.blogBlock--style4 .blogBlock__content {
  border: 0;
  box-shadow: none;
}

.blogBlock--style4 .blogBlock__button {
  text-align: right;
  margin-top: -35px;
  padding-right: 80px;
}

.blogBlock--style4 .blogBlock__button .btn {
  width: 70px;
  height: 70px;
  margin-bottom: 5px;
}

.blogBlock--style4 .blogBlock__text {
  font-size: 18px;
}

.blogBlock--style4 .blogBlock__meta ul {
  display: flex;
  gap: 86px;
  align-items: center;
  flex-wrap: wrap;
}

.blogBlock--style4 .blogBlock__meta ul li:nth-last-of-type(2) {
  flex: 1;
}

.blogBlock--style4 .blogBlock__meta ul li:hover a {
  color: #eb9309;
}

.blogBlock--style4 .blogBlock__meta__text {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  color: #666666;
  font-weight: 500;
}

.blogBlock--style4 .blogBlock__meta--style2 {
  padding-top: 20px;
  border-top: 1px solid #f4f4f4;
  padding-right: 55px;
}

.blogBlock--style4 .blogBlock__quotes {
  font-family: "Oswald", sans-serif;
  font-size: calc(1.28125rem + 0.375vw);
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.52em;
  color: #0d0d0d;
  background-color: #f3f3f3;
  padding: 65px 90px 65px 46px;
  margin-bottom: 0;
  position: relative;
}

@media (min-width: 1200px) {
  .blogBlock--style4 .blogBlock__quotes {
    font-size: 1.5625rem;
  }
}

.blogBlock--style4 .blogBlock__quotes::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: #eb9309;
}

.blogDetails-title__heading {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 500;
  margin-bottom: 0;
}

@media (min-width: 1200px) {
  .blogDetails-title__heading {
    font-size: 1.5rem;
  }
}

.blogDetails-tags {
  display: flex;
  align-items: center;
  gap: 30px;
}

.blogDetails-share {
  gap: 30px;
  justify-content: flex-end;
}

.blogDetails-share.itSocial ul {
  gap: 20px;
}

.blogDetails-share.itSocial ul li a {
  padding: 0;
  width: unset;
  height: unset;
  color: #0d0d0d;
  border: none;
}

.blogDetails-share.itSocial ul li a.facebook {
  color: #0072ac;
}

.blogDetails-share.itSocial ul li a.twitter {
  color: #3ac4ff;
}

.blogDetails-share.itSocial ul li a.instagram {
  color: #e1306c;
}

.blogDetails-share.itSocial ul li a.linkedin {
  color: #0072ac;
}

.blogDetails-share.itSocial ul li a.pinterest {
  color: #da0021;
}

.blogDetails-share.itSocial ul li:hover a {
  background-color: unset;
  color: unset;
}

.blogDetails-socialTags {
  padding: 40px 0;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
}

.comments ul {
  display: grid;
  gap: 62px;
}

.commentsBlock {
  display: flex;
  gap: 30px;
}

.commentsBlock__figure {
  overflow: hidden;
  border-radius: 50%;
}

.commentsBlock__content {
  flex: 1;
}

.commentsBlock__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}

.commentsBlock__name {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #0d0d0d;
}

.commentsBlock__date {
  font-size: 12px;
  font-weight: 600;
  color: #949392;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.commentsBlock__reply__link {
  font-size: calc(1.28125rem + 0.375vw);
  color: #0d0d0d;
}

@media (min-width: 1200px) {
  .commentsBlock__reply__link {
    font-size: 1.5625rem;
  }
}

.commentsBlock__text {
  font-size: 14px;
  margin-bottom: 0;
}

.commentsBlock--reply {
  padding-left: 100px;
}

.commentsPost__input input,
.commentsPost__input textarea {
  width: 100%;
  border: 1px solid #eeeeee;
  font-size: 14px;
  padding: 0 22px;
}

.commentsPost__input input::-moz-placeholder,
.commentsPost__input textarea::-moz-placeholder {
  color: #949392;
}

.commentsPost__input input:-ms-input-placeholder,
.commentsPost__input textarea:-ms-input-placeholder {
  color: #949392;
}

.commentsPost__input input::placeholder,
.commentsPost__input textarea::placeholder {
  color: #949392;
}

.commentsPost__input input {
  min-height: 60px;
}

.commentsPost__input textarea {
  min-height: 209px;
  padding: 17px 22px;
}

.commentsPost__check .form-check {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
}

.commentsPost__check .form-check-input {
  margin-top: 0;
  border-radius: 50%;
  width: 23px;
  height: 23px;
}

.commentsPost__check .form-check-label {
  font-size: 16px;
}

/* 3.12 Sponsors */

.sponsorsTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 37px;
  margin-bottom: 52px;
}

.sponsorsTitle__heading {
  font-size: 1.125rem;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .sponsorsTitle__heading {
    font-size: 15px;
  }
}

.sponsorsTitle__line {
  height: 1px;
  border: 1px solid #f3f3f3;
  flex-grow: 1;
}

.sponsorsTitle--style2 {
  margin-bottom: 31px;
}

.sponsorsTitle--style2 .sponsorsTitle__heading {
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {
  .sponsorsTitle--style2 .sponsorsTitle__heading {
    font-size: 1.875rem;
  }
}

.sponsorsItem {
  text-align: center;
  filter: grayscale(1);
}

.sponsors--style2 .sponsorsItem {
  filter: grayscale(0);
}

/* 3.13 Fact */

.factWrapper {
  margin: 0 110px;
}

.factWrapper--two {
  margin-top: -115px;
}

.factWrapper--two {
  margin-top: -55px;
}

@media (max-width: 1667px) {
  .factWrapper--two {
    margin-top: -85px;
  }
  .factWrapper--three {
    margin-top: -105px;
  }
}

@media (max-width: 1199px) {
  .factWrapper {
    margin: 0 0;
  }
  .factWrapper--two {
    margin-top: -45px;
  }
  .factWrapper--three {
    margin-top: -85px;
  }
}

.factBlock {
  min-height: 322px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 50%;
  min-width: 322px;
  display: grid;
  place-content: center;
  text-align: center;
  padding: 0 35px;
}

/* @media (max-width: 1087px) {
  .factBlock {
    min-width: 100%;
    min-height: 296px;
  }
} */
@media (max-width: 1087px) {
  .factBlock {
    width: 296px;
    height: 296px;
  }
}

.factBlock__heading {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-size: calc(1.34375rem + 1.125vw);
  margin-bottom: 14px;
}

@media (min-width: 1200px) {
  .factBlock__heading {
    font-size: 2.1875rem;
  }
}

.factBlock__ext {
  color: #eb9309;
}

.factBlock__tag {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #0d0d0d;
  display: block;
  margin-bottom: 13px;
}

.factBlock__text {
  margin-bottom: 0;
}

.factBlock--one {
  -webkit-animation: jump 3s linear infinite;
  animation: jump 3s linear infinite;
}

.factBlock--two {
  -webkit-animation: jump 5s linear infinite;
  animation: jump 5s linear infinite;
}

.factBlock--three {
  -webkit-animation: jump 8s linear infinite;
  animation: jump 8s linear infinite;
}

.factBlock--four {
  -webkit-animation: jump 10s linear infinite;
  animation: jump 10s linear infinite;
}

.factBlock--five {
  -webkit-animation: jump 12s linear infinite;
  animation: jump 12s linear infinite;
}

.fact--layout1 {
  z-index: 1;
}

.fact--layout1 .fact__map {
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: -1;
  opacity: 0.05;
}

/* 3.14 Volunteer */
.volunteerUser__box {
  display: inline-block;
  text-align: center;
}

.volunteerUser__thumb {
  border-radius: 50%;
  overflow: hidden;
}

.volunteerUser__profile {
  display: block;
  margin-top: -88px;
  position: relative;
}

.volunteerUser__profile ul {
  background: #fff;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);
  border-radius: 134px;
  padding: 30px;
  display: inline-flex;
  margin-left: -11px;
  position: relative;
  padding-right: 41px;
}

.volunteerUser__profile ul li {
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #fff;
  margin-right: -11px;
  position: relative;
}

.volunteerUser__profile ul li:nth-child(1) {
  z-index: 6;
}

.volunteerUser__profile ul li:nth-child(2) {
  z-index: 5;
}

.volunteerUser__profile ul li:nth-child(3) {
  z-index: 4;
}

.volunteerUser__profile ul li:nth-child(4) {
  z-index: 3;
}

.volunteerUser__profile ul li:nth-child(5) {
  z-index: 2;
}

.volunteerUser__profile ul li:nth-child(6) {
  z-index: 1;
}

.volunteerUser__profile ul li:last-child::after,
.volunteerUser__profile ul li:last-child::before {
  position: absolute;
}

.volunteerUser__profile ul li:last-child::before {
  border-radius: 50%;
  background: #000;
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0.6;
}

.volunteerUser__profile ul li:last-child::after {
  content: "200+";
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.volunteerBlock__name {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 15px;
}

.volunteerBlock__figure {
  margin-bottom: 31px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
  background-color: #fff;
}

.volunteerBlock__figure__thumb {
  border-radius: 50%;
  filter: grayscale(1);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.volunteerBlock:hover .volunteerBlock__figure__thumb {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transform: scale(1.2);
  filter: grayscale(0);
}

.liveDonation {
  box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);
  background: #fff;
  padding: 70px 90px;
  position: relative;
  z-index: 1;
  margin: 0 40px;
}

@media (max-width: 1087px) {
  .liveDonation {
    padding: 20px 15px;
    margin: 0 0px;
  }
}

.liveDonation .liveDonationTitle {
  margin-bottom: 50px;
}

.liveDonation .liveDonationTitle__small {
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #0d0d0d;
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 18px;
}

.liveDonation .liveDonationTitle__small span {
  width: 14px;
  height: 14px;
  background: #ff2200;
  display: inline-block;
  border-radius: 50%;
}

.liveDonation .liveDonationTitle__heading {
  font-size: calc(1.46875rem + 2.625vw);
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0;
}

@media (min-width: 1200px) {
  .liveDonation .liveDonationTitle__heading {
    font-size: 3.4375rem;
  }
}

.liveDonation .featureBlock__donation {
  padding: 0;
  margin-bottom: 0;
  border: 0;
  box-shadow: none;
}

.liveDonation .featureBlock__donation__text {
  transform: translateX(0px);
  margin-bottom: 15px;
  font-size: 15px;
  color: #eb9309;
}

.liveDonation .featureBlock__donation__line .skill-bars {
  height: 12px;
}

.liveDonation .featureBlock__donation__line .skill-bars::before {
  height: 12px;
}

.liveDonation .featureBlock__donation__line .skill-bars .skill-bar::before {
  height: 12px;
}

.liveDonation .featureBlock__donation__line .skill-bars .skill-bar::after {
  height: 37px;
  width: 37px;
  border-width: 12px;
  right: -1px;
}

.liveDonation .featureBlock__donation__progress {
  margin-bottom: 35px;
}

.liveDonation .featureBlock__eqn__title {
  font-size: 15px;
}

.liveDonation .featureBlock__eqn__price {
  font-size: 24px;
}

.liveDonation__button {
  text-align: right;
  margin-bottom: 50px;
}

@media (max-width: 1087px) {
  .liveDonation__button {
    text-align: left;
  }
}

.featureBlock__donation--style2 {
  padding: 0;
  border: 0;
  margin-bottom: 40px;
  box-shadow: none;
}

.featureBlock__donation--style2 .featureBlock__donation {
  padding: 0;
  margin-bottom: 0;
  border: 0;
  box-shadow: none;
}

.featureBlock__donation--style2 .featureBlock__donation__text {
  transform: translateX(0px);
  margin-bottom: 15px;
  font-size: 15px;
  color: #eb9309;
}

.featureBlock__donation--style2 .featureBlock__donation__line .skill-bars {
  height: 12px;
}

.featureBlock__donation--style2 .featureBlock__donation__line .skill-bars::before {
  height: 12px;
}

.featureBlock__donation--style2 .featureBlock__donation__line .skill-bars .skill-bar::before {
  height: 12px;
}

.featureBlock__donation--style2 .featureBlock__donation__line .skill-bars .skill-bar::after {
  height: 37px;
  width: 37px;
  border-width: 12px;
  right: -1px;
}

.featureBlock__donation--style2 .featureBlock__donation__progress {
  margin-bottom: 35px;
}

.featureBlock__donation--style2 .featureBlock__eqn__title {
  font-size: 15px;
}

.featureBlock__donation--style2 .featureBlock__eqn__price {
  font-size: 24px;
}

@media (max-width: 575px) {
  .featureBlock__donation--style2 .featureBlock__eqn__price {
    font-size: 20px;
  }
}

.donationDetails .donationDetails__header .thumb img {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  object-position: top;
}

.donationDetails__warning p {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 23px 0;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
}

.donationDetails__warning p svg path {
  fill: #eb9309;
}

.donationDetails__warning p span {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  padding-left: 9px;
  padding-right: 5px;
}

.donationDetails__title {
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {
  .donationDetails__title {
    font-size: 1.875rem;
  }
}

.donationDetails__heading {
  font-size: calc(1.275rem + 0.3vw);
  text-transform: uppercase;
}

@media (min-width: 1200px) {
  .donationDetails__heading {
    font-size: 1.5rem;
  }
}

@media (max-width: 1087px) {
  .donationDetails__heading {
    margin-bottom: 20px;
  }
}

.donationDetails__payments {
  padding: 37px 0;
}

.donationDetails__cross {
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
  padding: 19px 0;
}

.donationDetails__cross p {
  color: #a1a1a1;
  display: flex;
  align-items: baseline;
  gap: 12px;
  margin-bottom: 0;
}

.donationDetails__cross p svg {
  top: 8px;
  position: relative;
}

.donationDetails__text {
  font-size: 1.0625rem;
}

.payments__methods {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}

@media (max-width: 1087px) {
  .payments__methods {
    margin-bottom: 20px;
  }
}

.payments__method {
  display: inline-flex;
  align-items: center;
}

.payments__input {
  display: none;
}

@media (max-width: 1199px) {
  .paymentsInput {
    margin-bottom: 20px;
  }
}

.payments__label {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #001b36;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.payments__label__check {
  display: inline-flex;
  width: 16px;
  height: 16px;
  background-color: #f2f2f2;
  border-radius: 50%;
  border: 1px solid #dadada;
  position: relative;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.payments__label__check::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transform: translate(-50%, -50%) scale(0);
}

.payments__input:checked+.payments__label {
  color: #eb9309;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.payments__input:checked+.payments__label .payments__label__check {
  background-color: #eb9309;
  border-color: #eb9309;
}

.payments__input:checked+.payments__label .payments__label__check::before {
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transform: translate(-50%, -50%) scale(1);
}

.paymentsHeader,
.paymentsCustoms {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.paymentsHeader {
  margin-bottom: 25px;
}

.paymentsCustoms__field {
  min-height: 60px;
  padding: 0 25px;
  background: #fff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);
  border-radius: 143px;
  font-size: 14px;
  font-weight: 600;
  color: #0d0d0d;
}

.paymentsCustoms__field::-moz-placeholder {
  color: #0d0d0d;
  font-weight: 600;
}

.paymentsCustoms__field:-ms-input-placeholder {
  color: #0d0d0d;
  font-weight: 600;
}

.paymentsCustoms__field::placeholder {
  color: #0d0d0d;
  font-weight: 600;
}

.paymentsAmountChoice {
  display: flex;
  align-items: center;
  gap: 15px;
}

@media (max-width: 1199px) {
  .paymentsAmountChoice {
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .paymentsAmountChoice {
    flex-wrap: wrap;
    margin-top: 20px;
  }
}

.paymentsAmountChoice__label {
  background: #fdfdfd;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 143px;
  font-size: 14px;
  font-weight: 600;
  color: #a1a1a1;
  display: inline-grid;
  min-height: 60px;
  place-content: center;
  padding: 0 30px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.payments__input:checked+.paymentsAmountChoice__label {
  border-color: #dcdcdc;
  color: #eb9309;
  background-color: #fff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.services--style3 {
  padding: 40px 0 0;
}

@media (max-width: 1087px) {
  .services--style3 {
    padding-top: 40px;
  }
}

.pageBreadcumb {
  background: no-repeat center center;
  background-size: cover;
}

.pageBreadcumb--style1 {
  padding: 145px 0 80px;
  padding-bottom: 60px;
}

@media (max-width: 1233px) {
  .pageBreadcumb--style1 {
    padding: 40px 0 80px;
    padding-top: 60px;
    padding-bottom: 30px;
  }
}

.pageBreadcumb--style1::before,
.pageBreadcumb--style1::after {
  background: rgba(13, 13, 13, 0.45);
}

.pageTitle {
  position: relative;
  z-index: 1;
}

.pageTitle__heading {
  font-size: calc(1.5rem + 3vw);
}

@media (min-width: 1200px) {
  .pageTitle__heading {
    font-size: 3.75rem;
  }
}

.pageTitle .breadcrumb {
  justify-content: center;
}

.pageTitle .breadcrumb li {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

.pageTitle .breadcrumb li a {
  color: #fff;
}

.pageTitle .breadcrumb li a:hover {
  color: #eb9309;
}

.pageTitle .breadcrumb li.active {
  color: #eb9309;
}

.pageTitle .breadcrumb li.active::before {
  color: #fff;
}

.pageBreadcumbTopDown {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  margin-bottom: -46px;
  z-index: 9;
}

.cursor-ball {
  position: fixed;
  width: 10px;
  height: 10px;
  background: #eb9309;
  border-radius: 50%;
  left: 0;
  top: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: 0.2s;
  z-index: 1111;
}

.cursor-ball.inner-ball {
  background: none;
  border: 1.1px solid #eb9309;
  width: 35px;
  height: 35px;
}

@media (max-width: 1200px) {
  .cursor {
    display: none;
  }
}

.missionSection--style1 {
  padding-top: 125px;
  padding-bottom: 130px;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.missionSection--style1::before,
.missionSection--style1::after {
  background: rgba(13, 13, 13, 0.5);
  z-index: -1;
}

.missionBlock {
  display: flex;
  align-items: center;
  padding: 16px 25px;
  gap: 20px;
}

@media (max-width: 1199px) {
  .missionBlock {
    gap: 20px;
    padding: 25px 20px;
  }
}

.missionBlock__counter {
  font-size: 35px;
  font-weight: 500;
  color: #fff;
  font-family: "Oswald", sans-serif;
  display: block;
  margin-bottom: 2px;
  text-transform: uppercase;
}

.missionBlock__title {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #fff;
}

.mission-video-main {
  position: relative;
  display: inline-block;
}

.video {
  height: 117.37px;
  width: 117.37px;
  line-height: 50px;
  text-align: center;
  border-radius: 100%;
  background: transparent;
  color: #eb9309;
  display: grid;
  z-index: 999;
  place-content: center;
  font-size: 18px;
  border: 1px solid #fff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.video i {
  position: relative;
  z-index: 1;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.video::after,
.video::before {
  position: absolute;
  content: "";
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.video::before {
  height: 117.37px;
  width: 117.37px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-animation: waves 2s ease-in-out infinite;
  animation: waves 2s ease-in-out infinite;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.video::after {
  height: 118.37px;
  width: 118.37px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.15);
  -webkit-animation: waves 2s ease-in-out infinite;
  animation: waves 2s ease-in-out infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.video:hover {
  color: #fff;
  background-color: #eb9309;
  border-color: #eb9309;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.video:hover i {
  transform: scale(1.4);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.video:hover::before,
.video:hover::after {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  border-color: transparent;
}

.video:hover::before {
  background-color: rgba(235, 147, 9, 0.3);
}

.video:hover::after {
  background-color: rgba(235, 147, 9, 0.15);
}

@-webkit-keyframes waves {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes waves {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.fa-play:before {
  content: "\f04b";
}

.missionVideo {
  text-align: right;
  padding-top: 50px;
}

@media (max-width: 1666px) {
  .missionVideo {
    text-align: center;
  }
}

.storiesBlock {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 100px;
  padding: 59px 117px 59px 70px;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);
}

.storiesBlock__content {
  flex: 1;
}

.storiesBlock__thumb {
  border-radius: 50%;
  overflow: hidden;
}

.storiesBlock__thumb__link {
  display: inline-block;
}

.storiesBlock__thumb img {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.storiesBlock__heading {
  font-size: calc(1.3125rem + 0.75vw);
  margin-bottom: 24px;
  padding-right: 10%;
}

@media (min-width: 1200px) {
  .storiesBlock__heading {
    font-size: 1.875rem;
  }
}

.storiesBlock__meta {
  margin-bottom: 25px;
}

.storiesBlock__meta ul {
  display: flex;
  align-items: center;
  gap: 5px;
}

.storiesBlock__meta ul li {
  text-transform: uppercase;
  font-weight: 600;
}

.storiesBlock__meta__postUser {
  color: #0d0d0d;
}

.storiesBlock__meta__postUserName {
  color: #666666;
}

.storiesBlock__meta__date {
  color: #eb9309;
}

.storiesBlock__text {
  font-size: 1.125rem;
  margin-bottom: 30px;
}

.storiesBlock__detailsLink {
  font-size: 0.9375rem;
  text-transform: uppercase;
  color: #0d0d0d;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 16px;
}

.storiesBlock__detailsLink:hover svg path {
  fill: #eb9309;
}

.storiesBlock:hover .storiesBlock__thumb img {
  transform: scale(1.15);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.storiesDetails__text--first::first-letter {
  color: #0d0d0d;
  float: left;
  padding: 0px 22px;
  margin-right: 10px;
  font-size: calc(1.34375rem + 1.125vw);
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);
  background-color: #fff;
}

@media (min-width: 1200px) {
  .storiesDetails__text--first::first-letter {
    font-size: 2.1875rem;
  }
}

.eventsBlock {
  display: flex;
  align-items: center;
  gap: 82px;
  flex-wrap: wrap;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);
}

.flex-nowrappp {
  flex-wrap: nowrap;
  gap: 42px;
  align-items: center;
}

@media (max-width: 999px) {
  .flex-nowrappp {
    flex-wrap: wrap;
  }
}

@media (max-width: 1199px) {
  .eventsBlock {
    gap: 30px;
  }
}

.eventsBlock__thumb {
  position: relative;
}

@media (max-width: 1087px) {
  .eventsBlock__thumb {
    width: 100%;
  }
}

@media (max-width: 1087px) {
  .eventsBlock__thumb__image {
    width: 100%;
  }
}

.eventsBlock__content {
  flex: 1;
  padding: 0 115px 0 0;
}

@media (max-width: 1199px) {
  .eventsBlock__content {
    padding: 30px;
  }
}

@media (max-width: 1087px) {
  .eventsBlock__content {
    flex: 100%;
    padding: 15px;
  }
}

.eventsBlock__heading {
  font-size: calc(1.3125rem + 0.75vw);
  margin-bottom: 26px;
}

@media (min-width: 1200px) {
  .eventsBlock__heading {
    font-size: 1.875rem;
  }
}

.eventsBlock__meta {
  margin-bottom: 35px;
}

.eventsBlock__meta ul {
  display: grid;
  gap: 18px;
}

.eventsBlock__meta ul li {
  text-transform: uppercase;
  font-size: 15px;
}

.eventsBlock__meta__title {
  font-weight: 700;
  color: #0d0d0d;
}

.eventsBlock__meta__text {
  color: #acacac;
}

.eventsBlock__text {
  font-size: 1.125rem;
  margin-bottom: 40px;
}

.eventsBlock__detailsLink {
  font-size: 0.9375rem;
  text-transform: uppercase;
  color: #0d0d0d;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 16px;
}

.eventsBlock__detailsLink:hover svg path {
  fill: #eb9309;
}

.eventAddress {
  background-color: #f4f4f4;
  padding: 49px 39px;
}

.eventAddress ul {
  display: flex;
  gap: 40px;
}

.eventAddress ul li {
  display: grid;
  gap: 13px;
}

.eventAddress ul li span {
  display: block;
  font-size: 15px;
}

.eventAddress__heading {
  font-size: 18px;
  margin-bottom: 7px;
}

.shopFilter__tab {
  border: 1px solid #f4f4f4;
  border-radius: 0px;
  padding: 11px 30px;
}

.shopFilter__tab ul {
  gap: 8px;
  border-bottom: 0;
  align-items: center;
}

.shopFilter__tab ul li {
  margin: 0;
}

.shopFilter__tab ul li .nav-link {
  width: unset;
  height: unset;
  padding: 0;
  display: grid;
  place-content: center;
  background: transparent;
  border-radius: 5px;
  border: 0;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.shopFilter__tab ul li .nav-link.active {
  background-color: transparent;
}

.shopFilter__tab ul li .nav-link.active path {
  fill: #eb9309;
}

.shopFilter__tab ul li .count {
  color: #7fb432;
}

.shopFilter__tab__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 113px;
}

.product-result {
  font-size: 15px;
  font-weight: 500;
  color: #0d0d0d;
}

.userProfileFilter__sort {
  border: none;
  height: unset;
  line-height: 1;
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  padding: 5px 0;
  padding-right: 20px;
}

.userProfileFilter__sort::after {
  content: "\f063";
  font-family: fontAwesome;
  border: 0;
  width: unset;
  transform: unset;
  height: unset;
  transform: translateY(-50%);
  margin-top: 0;
  right: 0;
}

.userProfileFilter__sort.open::after {
  transform: translateY(-50%);
  content: "\f062";
}

.productBlock {
  border: 1px solid #f4f4f4;
  background-color: #fff;
  position: relative;
  padding: 45px 30px 30px 30px;
}

.productBlock__name {
  font-size: 1.25rem;
  font-weight: 500;
  color: #0d0d0d;
  text-transform: uppercase;
}

.productBlock__content {
  text-align: center;
  position: relative;
}

.productBlock__content__hover {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transform: translateY(50px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.productBlock__content__hover::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: scaleY(0);
  transform-origin: bottom;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.productBlock__price {
  display: block;
  color: #8b8f9e;
  color: #fc4351;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 5px;
}

.productBlock__ratings {
  font-size: 12px;
}

.productBlock__ratings .active {
  color: #f7931e;
}

.productBlock__thumb {
  min-height: 190px;
  text-align: center;
  margin-bottom: 15px;
  position: relative;
}

.productBlock__thumb__hover {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.productBlock__thumb__main {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.productBlock__tags {
  position: absolute;
  top: 25px;
  left: 25px;
  display: inline-block;
  text-transform: uppercase;
  background: #fc4351;
  border-radius: 3px;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.05em;
  padding: 3px 13px;
  z-index: 99;
}

.productBlock__actions {
  display: flex;
  justify-content: center;
  gap: 7px;
}

.productBlock__actions button {
  background-color: #fff;
  border: 1px solid #edeffa;
  border-radius: 0;
  display: grid;
  place-content: center;
  width: 40px;
  height: 40px;
  position: relative;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  z-index: 1;
}

.productBlock__actions button::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  display: inline-block;
  background-color: #eb9309;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.productBlock__actions button:hover {
  color: #fff;
  border-color: #eb9309;
  background-color: #eb9309;
  opacity: 1;
  visibility: visible;
}

.productBlock__actions button:hover svg path {
  fill: #fff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.productBlock__actions button:hover::after {
  transform: scale(1, 1) rotate(0deg);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.productBlock:hover .productBlock__thumb__main {
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.productBlock:hover .productBlock__thumb__hover {
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.productBlock:hover .productBlock__content__hover {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.productBlock:hover .productBlock__content__hover::before {
  transform: scaleY(1);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  opacity: 1;
  visibility: visible;
}

.productBlock--style2 {
  display: flex;
  gap: 15px;
}

.productBlock--style2 .productBlock__thumb {
  min-width: 200px;
}

.productBlock--style2 .productBlock__content {
  flex: 1;
  text-align: left;
}

.productBlock--style2 .productBlock__content__hover {
  justify-content: flex-start;
  align-items: center;
}

.productBlock--style2 .productBlock__price {
  color: #fc4351;
  font-weight: 700;
  font-size: 20px;
}

.productBlock--style2 .productBlock__text {
  margin-bottom: 5px;
}

.bs-tooltip-top {
  margin-top: 50px;
}

.bs-tooltip-bottom {
  margin-bottom: 50px;
}

.tooltip-inner {
  font-size: 12px;
  padding: 10px 21px;
  color: #a1a1a1;
  text-align: center;
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 700;
  border: 1px solid #edeffa;
  border-radius: 2px;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
  left: 50% !important;
  transform: translate(-50%, 0px) !important;
}

.bs-tooltip-top .tooltip-arrow {
  bottom: 1px;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^="top"] .tooltip-arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #edeffa;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow::before {
  bottom: 0;
  border-bottom-color: #edeffa;
}

.productImages-gallery__main {
  min-width: 470px;
  min-height: 503px;
  border: 1px solid #f4f4f4;
  display: grid;
  place-content: center;
  margin-right: 50px;
}

.productImages-gallery__main img {
  width: 250px;
  object-fit: cover;
}

.productImages-gallery__small {
  min-width: 105px;
  min-height: 108px;
  display: grid;
  place-content: center;
  border: 1px solid #f4f4f4;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.productImages-gallery .nav {
  gap: 22px;
}

.productImages-gallery .nav-link {
  padding: 0;
}

.productImages-gallery .nav-link.active {
  background-color: transparent;
}

.productImages-gallery .nav-link.active .productImages-gallery__small {
  border-color: #eb9309;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.productImages-gallery__tabs {
  gap: 28px;
}

.productDetails-block__heading {
  font-size: calc(1.3125rem + 0.75vw);
  text-transform: uppercase;
}

@media (min-width: 1200px) {
  .productDetails-block__heading {
    font-size: 1.875rem;
  }
}

.productDetails-block__rating {
  margin-bottom: 30px;
}

.productDetails-block__rating ul li i {
  color: #f7931e;
  font-size: 14px;
  gap: 3px;
}

.productDetails-block__rating ul li span {
  padding-left: 14px;
  font-weight: 500;
  color: #121416;
}

.productDetails-block__price>span {
  font-size: 30px;
  color: #000;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
}

.productDetails-block__price>span sub {
  bottom: 0;
  color: #b9b9b9;
}

.productDetails-block__title {
  font-size: 16px;
  font-weight: 500;
  color: #0d0d0d;
  display: block;
  margin-bottom: 10px;
}

.productDetails-block__model {
  gap: 16px;
}

.productDetails-block__model__single input {
  display: none;
}

.productDetails-block__model__single label {
  font-size: 12px;
  font-weight: 600;
  color: #8b8f9e;
  text-transform: uppercase;
  background-color: #f4f4f4;
  padding: 3px 13px;
  display: inline-flex;
  align-items: center;
  letter-spacing: 0.05em;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.productDetails-block__model__single input:checked+label {
  color: #fff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  background-color: #7fb432;
}

.productDetails-block__quantity {
  display: flex;
  align-items: center;
  gap: 10px;
}

.productDetails-block__quantity .btn {
  background-color: transparent;
  font-size: 19px;
  width: unset;
  height: unset;
  padding: 0;
  display: grid;
  place-content: center;
  box-shadow: none;
}

.productDetails-block__quantity .input-group>.form-control,
.productDetails-block__quantity .input-group>.form-select {
  flex: 0 0 auto;
  min-width: 30px;
  border: 0;
  padding: 0;
}

.productDetails-block__quantity .input-group>.form-control:focus,
.productDetails-block__quantity .input-group>.form-control:active,
.productDetails-block__quantity .input-group>.form-control:hover,
.productDetails-block__quantity .input-group>.form-select:focus,
.productDetails-block__quantity .input-group>.form-select:active,
.productDetails-block__quantity .input-group>.form-select:hover {
  -webkit-appearance: none;
  margin: 0;
}

.productDetails-block__quantity .input-group-btn button {
  font-size: 14px;
}

.productDetails-block__quantity [data-dir="dwn"] {
  color: #8d8884;
}

.productDetails-block__buttons {
  gap: 33px;
}

.btn--styleOne {
  border-radius: 0;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  padding: 17px 40px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  border: 0;
  box-shadow: none;
}

.btn--styleOne:hover {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  color: #fff;
}

.btn--styleOne:focus,
.btn--styleOne:active {
  outline: none;
  border: 0;
  box-shadow: none;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.btn--styleOne.btn--primary {
  background-color: #eb9309;
}

.btn--styleOne.btn--primary:hover {
  background-color: #7fb432;
  color: #fff;
}

.btn--styleOne.btn--secondary {
  background-color: #7fb432;
}

.btn--styleOne.btn--secondary:hover {
  background-color: #eb9309;
  color: #fff;
}

.btn--icon {
  background: #fff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.24);
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
  width: 55px;
  height: 55px;
  place-content: center;
}

.btn--icon .btn__icon {
  color: #60646b;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.btn--icon .btn__icon path {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.btn--icon:hover {
  border-color: transparent;
  background: #fff;
}

.btn--icon:hover .btn__icon {
  color: #fff;
}

.btn--icon:hover .btn__icon path {
  fill: #fff;
}

.btn--icon2 {
  width: 92px;
  height: 92px;
  border: 0;
  box-shadow: 0px 4px 40px rgba(139, 139, 139, 0.18);
}

.btn__icon {
  -webkit-animation: scale-up-one 1.5s linear infinite;
  animation: scale-up-one 1.5s linear infinite;
}

.it-btn {
  z-index: 1;
  transition: color 0.5s;
  position: relative;
}

.it-btn__inner {
  z-index: -1;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #eb9309;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.btn--secondary .it-btn__inner {
  background-color: #7fb432;
}

.btn--black .it-btn__inner {
  background-color: #000;
}

.btn--icon .it-btn__inner {
  background: transparent;
}

.it-btn__blobs {
  position: relative;
  display: block;
  height: 100%;
  filter: url("#goo");
}

.it-btn__blob {
  position: absolute;
  width: 25%;
  height: 100%;
  background: #7fb432;
  border-radius: 100%;
  transform: translate3d(0, 150%, 0) scale(1.7);
  transition: transform 0.65s;
}

.btn--secondary .it-btn__blob {
  background-color: #eb9309;
}

.btn--black .it-btn__blob {
  background-color: #eb9309;
}

.btn--icon .it-btn__blob {
  background: #eb9309;
}

@supports (filter: url("#goo")) {
  .it-btn__blob {
    transform: translate3d(0, 150%, 0) scale(1.4);
  }
}

.it-btn__blob:nth-child(1) {
  left: 0%;
  transition-delay: 0s;
}

.it-btn__blob:nth-child(2) {
  left: 30%;
  transition-delay: 0.09s;
}

.it-btn__blob:nth-child(3) {
  left: 60%;
  transition-delay: 0.18s;
}

.it-btn__blob:nth-child(4) {
  left: 90%;
  transition-delay: 0.27s;
}

.it-btn:hover .it-btn__blob {
  transform: translateZ(0) scale(1.7);
}

@supports (filter: url("#goo")) {
  .it-btn:hover .it-btn__blob {
    transform: translateZ(0) scale(1.4);
  }
}

.it-btn__animation {
  position: absolute;
  bottom: 0;
  height: 100%;
  left: 0;
  width: 100%;
  z-index: 11;
}

.itSocial ul {
  display: inline-flex;
  gap: 8px;
}

.itSocial ul li a {
  height: 50px;
  width: 50px;
  display: grid;
  place-content: center;
  border: 1px solid rgba(247, 247, 247, 0.15);
  border-radius: 50%;
  font-size: 14px;
  color: #fff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.itSocial ul li:hover a {
  background: #7fb432;
  border-color: #7fb432;
  color: #fff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.itSocial--sidebar ul li a {
  color: #666666;
  border-color: #666666;
}

.itSocial--volunteer ul {
  gap: 30px;
}

.itSocial--volunteer ul li a {
  color: #666666;
  height: unset;
  width: unset;
  border: none;
}

.itSocial--volunteer ul li:hover a {
  background-color: transparent;
  color: #eb9309;
}

.sectionTitle__small {
  color: #eb9309;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  font-weight: 600;
  font-size: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  .sectionntitle {
    text-align: center;
  }

  .sectionSubtitle {
    justify-content: center;
  }

  .maindiv {
    margin: auto;
  }
}

.sectionTitle__small i {
  -webkit-animation: scale-up-one 2s linear infinite;
  animation: scale-up-one 2s linear infinite;
}

.sectionTitle__big {
  line-height: 1.19;
  text-transform: uppercase;
  /* font-size: 34px;   */
}

.sliderNav__btn {
  width: 63px;
  height: 54px;
  display: grid;
  place-content: center;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  cursor: pointer;
}

.sliderNav--style1 {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.sliderNav--style1 .sliderNav__btn.btn-prev {
  background: #0d0d0d;
}

.sliderNav--style1 .sliderNav__btn.btn-next {
  background: #eb9309;
}

.sliderNav--style1 .sliderNav__btn:hover {
  background: #7fb432;
}

@media (max-width: 767px) {
  .sliderNav--style1 {
    justify-content: flex-start;
  }
}

.sliderNav--style2 {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 120px;
  z-index: 30;
}

@media (max-width: 1087px) {
  .sliderNav--style2 {
    top: 20%;
    transform: translateY(0%);
    padding: 0 50px;
  }
}

.sliderNav--style2 .sliderNav__btn {
  border: 1px solid #f1f1f1;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.sliderNav--style2 .sliderNav__btn svg path {
  fill: #0d0d0d;
}

.sliderNav--style2 .sliderNav__btn:hover {
  background-color: #eb9309;
  color: #fff;
  border-color: #eb9309;
}

.sliderNav--style2 .sliderNav__btn:hover svg path {
  fill: #fff;
}

.testi-slider-active2 {
  padding-top: 165px;
}

.review--layout2 .swiper-pagination {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  position: relative;
}

.review--layout2 .swiper-pagination-bullet {
  width: 13px;
  height: 13px;
  background-color: transparent;
  border: 1px solid #c9c9c9;
  opacity: 1;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.review--layout2 .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #eb9309;
  border-color: #eb9309;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.contactBlock {
  padding: 0 50px 50px 50px;
  border: 1px solid #eeeeee;
}

.contactBlock__figure {
  margin-bottom: 35px;
  margin-top: -66px;
}

.contactBlock__figure__thumb {
  border-radius: 50%;
}

.contactBlock__heading {
  font-size: calc(1.275rem + 0.3vw);
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .contactBlock__heading {
    font-size: 1.5rem;
  }
}

.contactBlock__text {
  font-size: 1.125rem;
  margin-bottom: 10px;
}

.contactBlock a {
  display: block;
  color: #666666;
  font-size: 18px;
}

.commentsPost--style2 {
  padding: 80px 112px;
  border: 1px solid #eeeeee;
  background-color: #fff;
  position: relative;
  z-index: 99;
  padding-bottom: 40px;
}

@media (max-width: 1087px) {
  .commentsPost--style2 {
    padding: 15px;
  }
}

.commentsPost--style2 .errorMessage {
  color: red;
}

#myMap {
  margin-top: -120px;
}

#myMap iframe {
  width: 100%;
  min-height: 704px;
}

.ccFaqBlock {
  margin-left: 12%;
}

@media (max-width: 1087px) {
  .ccFaqBlock {
    padding: 0 0;
    margin-left: 0;
  }
}

.ccFaqBlock .accordion .accordion-button {
  font-size: calc(1.275rem + 0.3vw);
  background: transparent;
  padding-left: 0px;
  color: #eb9309;
  border-color: transparent;
  box-shadow: none;
  text-transform: uppercase;
  font-weight: 500;
  padding: 42px 20px 42px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

@media (min-width: 1200px) {
  .ccFaqBlock .accordion .accordion-button {
    font-size: 1.5rem;
  }
}

.ccFaqBlock .accordion .accordion-button::after {
  background-image: none;
  display: none;
}

.ccFaqBlock .accordion .accordion-button::before {
  content: "\f148";
  font-family: fontAwesome;
  left: auto;
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.ccFaqBlock .accordion .accordion-button.collapsed {
  color: #0d0d0d;
  border-bottom: 1px solid #f6f3ee;
}

.ccFaqBlock .accordion .accordion-button.collapsed::before {
  content: "\f149";
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.ccFaqBlock .accordion .accordion-item {
  background-color: transparent;
  border: 0;
  margin-bottom: 0px;
}

.ccFaqBlock .accordion .accordion-body {
  color: #666666;
  padding: 0px 0px 35px;
  border-bottom: 1px solid #f6f3ee;
  font-size: 18px;
}

.paginationBlock {
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  margin-bottom: 40px;
}

.paginationBlock>nav>ul {
  align-items: center;
  gap: 7px;
}

.paginationBlock>nav>ul .page-item--prev .page-link,
.paginationBlock>nav>ul .page-item--next .page-link {
  min-height: 48px;
  border: 0;
  box-shadow: none;
  display: grid;
  place-content: center;
  min-width: 48px;
  border-radius: 6px;
  color: #a1a1a1;
  border: 1px solid #f2f2f2;
}

.paginationBlock__number {
  min-height: 48px;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 6px;
}

.paginationBlock__number>ul {
  display: flex;
  align-items: center;
  gap: 7px;
}

.paginationBlock__number>ul .page-item .page-link {
  box-shadow: none;
  border: 0;
  width: 48px;
  height: 48px;
  display: grid;
  place-content: center;
  font-size: 16px;
  color: #66687b;
  border-radius: 6px;
  border: 1px solid #f2f2f2;
}

.paginationBlock__number>ul .page-item .page-link:hover {
  background-color: #eb9309;
  color: #fff;
  border-color: #eb9309;
  box-shadow: 4px 4px 8px rgba(235, 147, 9, 0.25);
}

/* Team Details Pages - Start */
[class*="_details_section"] .icon_list.unordered_list_block {
  gap: 6px;
}

[class*="_details_section"] .icon_list>li {
  font-size: 18px;
  line-height: 32px;
}

.icon_list .icon_list_icon:has(.fa-circle) {
  font-size: 6px;
  margin: -1px 0 0;
  color: var(--bs-primary);
}

.icon_list>li {
  gap: 10px;
  display: flex;
  font-size: 16px;
  line-height: 28px;
  align-items: flex-start;
}

[class*="_details_section"] p {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 38px;
}

[class*="_details_section"] hr {
  margin: 30px 0;
  background-color: #cce3ff;
}

[class*="_details_section"] .accordion p {
  font-size: 16px;
  line-height: 30px;
}

[class*="_details_section"] .post_meta_wrap {
  row-gap: 15px;
  display: flex;
  column-gap: 50px;
  align-items: center;
}

[class*="_details_section"] .post_meta_wrap .post_meta {
  margin-top: 3px;
}

[class*="_details_section"] .category_btns_group a {
  border: 1px solid #cce3ff;
}

[class*="_details_section"] .category_btns_group a:hover {
  border-color: var(--bs-primary);
}

.details_item_image {
  overflow: hidden;
  position: relative;
  margin-bottom: 50px;
  border-radius: var(--bs-border-radius-sm);
}

.details_item_title {
  font-size: 40px;
  line-height: 55px;
  margin-bottom: 20px;
}

.details_item_info_title {
  font-size: 35px;
  line-height: 50px;
  margin-bottom: 17px;
}

.team_member_details_card {
  padding: 40px;
  display: flex;
  align-items: center;
  background-color: var(--bs-white);
  border-radius: var(--bs-border-radius-sm);
  box-shadow: 0px 2px 12px 2px rgba(4, 0, 52, 0.1);
}

.team_member_details_card .team_member_image {
  height: 454px;
  flex: 0 0 490px;
  overflow: hidden;
  margin: 0 100px 0 0;
  display: inline-flex;
  justify-content: center;
}

.team_member_details_card .team_member_image img {
  height: 100%;
  border-radius: 50%;
}

.team_member_details_card .team_member_name {
  font-size: 35px;
  line-height: 50px;
  margin-bottom: 30px;
}

.team_member_details_card .icon_list {
  gap: 14px;
  margin-bottom: 40px;
}

.team_member_details_card .icon_list>li {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 10px;
}

.team_member_details_card .icon_list strong {
  color: var(--bs-dark);
}

.team_member_details_card .social_title {
  line-height: 1;
  font-size: 20px;
  margin-bottom: 20px;
}

.team_member_details_card .social_icons_block {
  gap: 10px;
  display: flex;
}

.team_member_details_card .social_icons_block a {
  width: 40px;
  height: 40px;
  display: flex;
  flex: 0 0 auto;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #ebebeb;
  color: #333;
}

.team_member_details_card .social_icons_block a:hover {
  background-color: #eb9309;
  border-color: #eb9309;
  color: #fff;
}

.funfact_block {
  position: relative;
  padding: 40px 40px 30px;
  background-color: var(--bs-white);
  border-radius: var(--bs-border-radius-sm);
  box-shadow: 0 4px 23px 0 rgba(174, 191, 210, 0.3);
}

.funfact_block .funfact_icon {
  height: 80px;
  margin-bottom: 59px;
}

.funfact_block .funfact_icon img {
  max-height: 80px;
}

.funfact_block .counter_value {
  line-height: 1;
  font-size: 45px;
  font-weight: 700;
  align-items: center;
  margin-bottom: 10px;
  display: inline-flex;
  color: var(--bs-dark);
}

.funfact_block .funfact_title {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: var(--bs-body-color);
}

.funfact_block .bottom_line {
  bottom: 0;
  left: 50%;
  width: 55%;
  height: 4px;
  position: absolute;
  transform: translateX(-50%);
}

.funfact_block:has(.bottom_line) {
  box-shadow: none;
  padding: 75px 40px 65px;
  border: 1px solid #e7e9ee;
}

.funfact_block:has(.bottom_line) .counter_value {
  font-size: 65px;
  margin-bottom: 20px;
}

.funfact_block:has(.bottom_line) .funfact_title {
  font-size: 18px;
  font-weight: 600;
}

.funfact_block.capsule_layout {
  padding: 6px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  justify-content: space-between;
  box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.15);
}

.funfact_block.capsule_layout .funfact_content {
  gap: 10px;
  padding: 0 40px;
  display: inline-flex;
  flex-direction: column;
}

.funfact_block.capsule_layout .counter_value {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0px;
}

.funfact_block.capsule_layout .funfact_title {
  line-height: 1;
  font-size: 14px;
}

.funfact_block.capsule_layout .funfact_icon {
  margin: 0;
  width: 78px;
  height: 78px;
  flex: 0 0 auto;
  align-items: center;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  background-color: #47b16a;
}

.product-details .product-tab-area {
  padding-top: 100px;
}

.product-details .product-tab-area .tab-content {
  padding-top: 50px;
}

@media (max-width: 1087px) {
  .product-details .product-tab-area .tab-content {
    padding-top: 40px;
  }
}

.product-details .product-tab-area .main-tab {
  justify-content: flex-start;
  border-bottom: 1px solid rgba(93, 102, 111, 0.15);
}

.product-details .product-tab-area .main-tab .nav-link {
  color: rgba(93, 102, 111, 0.5);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  padding: 10px 40px;
  background: transparent;
  border: none;
  margin-bottom: 20px;
  border-radius: 0;
  cursor: pointer;
}

@media (max-width: 767px) {
  .product-details .product-tab-area .main-tab .nav-link {
    padding: 10px 10px;
  }
}

.product-details .product-tab-area .main-tab .nav-link.active {
  color: #1c263a;
  background: #f3f3f3;
}

.product-details .product-tab-area .Descriptions-item .Description-table {
  margin-top: 30px;
}

.product-details .product-tab-area .Descriptions-item .Description-table h4 {
  margin-bottom: 20px;
}

.product-details .product-tab-area .Descriptions-item .Description-table th,
.product-details .product-tab-area .Descriptions-item .Description-table td {
  width: 8%;
  border: 1px solid #e1e2e1;
  text-align: center;
  font-weight: 400;
  color: #414141;
  padding: 20px;
}

.product-details .rating-section .rating-sub span {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #707070;
}

.product-details .rating-section .rating-sub ul {
  display: flex;
  margin-bottom: 20px;
}

.product-details .rating-section .rating-sub ul li {
  margin-right: 10px;
  font-size: 40px;
  color: #ffd400;
}

.product-details .rating-section .rating-right .rating-right-item {
  margin-bottom: 30px;
}

.product-details .rating-section .rating-right ul {
  display: flex;
  align-items: center;
}

.product-details .rating-section .rating-right ul li {
  flex-basis: 20%;
}

.product-details .rating-section .rating-right ul li:nth-child(2) {
  flex-basis: 60%;
}

.product-details .rating-section .rating-right ul li ul li {
  flex-basis: unset;
  margin-right: 10px;
  font-size: 18px;
  color: #ffd400;
}

.product-details .rating-section .rating-right ul li ul li:nth-child(2) {
  flex-basis: unset;
}

.product-details .rating-section .rating-right ul li .progress {
  margin: 0 auto;
  padding: 0;
  width: 90%;
  height: 6px;
  overflow: hidden;
  background: #e5e5e5;
  border-radius: 6px;
}

.product-details .rating-section .rating-right ul li .bar {
  position: relative;
  float: left;
  height: 100%;
  background: #1c263a;
}

.product-details .rating-section .rating-bottom {
  padding-top: 30px;
}

.product-details .rating-section .rating-bottom .theme-btn {
  margin-bottom: 30px;
}

.product-details .rating-section .rating-bottom .rating-give-section-items {
  display: none;
}

.product-details .rating-section .rating-bottom.active .rating-give-section-items {
  display: block;
}

.product-details .rating-section .rating-bottom.active .write-review-btn {
  display: none;
}

.product-details .rating-section .rating-bottom .rating-give-section-wrap .theme-btn {
  margin-bottom: 0px;
}

.product-details .rating-section .rating-bottom .rating-give-section {
  padding: 30px;
  border: 1px solid rgba(93, 102, 111, 0.15);
}

.product-details .rating-section .rating-bottom .rating-give-section span {
  display: block;
}

.product-details .rating-section .rating-bottom .rating-give-section .give-rating {
  display: inline-block;
  position: relative;
  height: 50px;
  line-height: 50px;
  font-size: 50px;
  margin-bottom: 30px;
}

.product-details .rating-section .rating-bottom .rating-give-section .give-rating label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  cursor: pointer;
}

.product-details .rating-section .rating-bottom .rating-give-section .give-rating label:last-child {
  position: static;
}

.product-details .rating-section .rating-bottom .rating-give-section .give-rating label:nth-child(1) {
  z-index: 5;
}

.product-details .rating-section .rating-bottom .rating-give-section .give-rating label:nth-child(2) {
  z-index: 4;
}

.product-details .rating-section .rating-bottom .rating-give-section .give-rating label:nth-child(3) {
  z-index: 3;
}

.product-details .rating-section .rating-bottom .rating-give-section .give-rating label:nth-child(4) {
  z-index: 2;
}

.product-details .rating-section .rating-bottom .rating-give-section .give-rating label:nth-child(5) {
  z-index: 1;
}

.product-details .rating-section .rating-bottom .rating-give-section .give-rating label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.product-details .rating-section .rating-bottom .rating-give-section .give-rating label .icon {
  float: left;
  color: transparent;
}

.product-details .rating-section .rating-bottom .rating-give-section .give-rating label:last-child .icon {
  color: #000;
}

.product-details .rating-section .rating-bottom .rating-give-section .give-rating:not(:hover) label input:checked~.icon,
.product-details .rating-section .rating-bottom .rating-give-section .give-rating:hover label:hover input~.icon {
  color: #ffd400;
}

.product-details .rating-section .rating-bottom .rating-give-section .give-rating label input:focus:not(:checked)~.icon:last-child {
  color: #000;
  text-shadow: 0 0 5px #ffd400;
}

.product-details .rating-section .rating-bottom .rating-give-section .form-group input,
.product-details .rating-section .rating-bottom .rating-give-section .form-group textarea {
  width: 100%;
  background: transparent;
  padding: 15px;
  height: 70px;
  border: 1px solid rgba(93, 102, 111, 0.15);
  margin-bottom: 30px;
}

.product-details .rating-section .rating-bottom .rating-give-section .form-group textarea {
  height: 200px;
}

.product-details .rating-section .rating-bottom .review-rating-wrap {
  padding-top: 40px;
}

.product-details .rating-section .rating-bottom .rating-review {
  padding: 30px;
  border: 1px solid rgba(93, 102, 111, 0.15);
  border-bottom: 0;
}

.product-details .rating-section .rating-bottom .rating-review:last-child {
  border-bottom: 1px solid rgba(93, 102, 111, 0.15);
  text-align: center;
}

.product-details .rating-section .rating-bottom .rating-review:last-child a {
  color: #1c263a;
}

.product-details .rating-section .rating-bottom .rating-review p {
  margin-bottom: 0;
}

.product-details .rating-section .rating-bottom .rating-review .rating-review-author {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.product-details .rating-section .rating-bottom .rating-review .rating-review-author .rating-review-author-img img {
  border-radius: 50%;
}

.product-details .rating-section .rating-bottom .rating-review .rating-review-author-text p {
  margin-left: 20px;
  margin-bottom: 0;
}

.product-details .rating-section .rating-bottom .rating-review .rating-review-author-text-inner {
  display: flex;
  margin-left: 20px;
}

.product-details .rating-section .rating-bottom .rating-review .ratting {
  display: flex;
}

.product-details .rating-section .rating-bottom .rating-review .ratting li {
  margin-right: 5px;
  color: #ffd400;
}

.product-details .owl-nav [class*="owl-"] {
  position: absolute;
  left: 10px;
  top: 46%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
}

.product-details .owl-nav [class*="owl-"].owl-next {
  left: auto;
  right: 10px;
}

.product-details:hover .owl-nav [class*="owl-"] {
  opacity: 1;
  visibility: visible;
}

.product-details .description-section {
  margin-top: 20px;
}

.product-details .description-section .Descriptions-item p {
  max-width: 100%;
}

.product-details .accordion-item {
  border: 0;
  border-bottom: 1px solid #e1e1e1;
  padding: 5px 0px;
}

.product-details .accordion-item .accordion-body {
  padding-left: 0;
  padding-right: 0;
}

.product-details .accordion-item button {
  padding: 15px 0px;
  border: 0;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  color: #1c263a;
  text-align: left;
  font-family: "Rubik", sans-serif;
}

.product-details .accordion-item button::after {
  background: none;
  font-family: "icomoon";
  content: "\e918";
  font-size: 15px;
  transform: rotate(0deg);
  font-weight: 700;
  color: #7fb432;
}

.product-details .accordion-item button.collapsed {
  color: #373b3e;
}

.product-details .accordion-item button.collapsed::after {
  content: "\e917";
}

.product-details .accordion-item button:focus {
  box-shadow: none;
  outline: none;
  border-color: #e1e1e1;
}

.product-details .accordion-item .accordion-collapse {
  border: 0;
}

.product-details .accordion-button {
  background: transparent;
}

.product-active {
  margin-bottom: 15px;
}

.product-active .owl-prev,
.product-active .owl-next {
  display: none;
}

.Additional-wrap .ratting ul {
  display: flex;
  justify-content: center;
}

.Additional-wrap .ratting ul li {
  margin-right: 5px;
  color: #ffbf4e;
}

.Additional-wrap th,
.Additional-wrap td {
  width: 8%;
  border: 1px solid #e1e2e1;
  text-align: center;
  font-weight: 400;
  color: #414141;
  padding: 20px;
}

.Additional-wrap {
  padding-top: 0;
}

/*===========================
 26. cart-page-style
===========================*/
@media (max-width: 1087px) {
  .cart-area .cart-wrapper table {
    width: 1022px;
  }
}

@media (max-width: 1087px) {
  .cart-area .cart-wrapper form {
    overflow: scroll;
    overflow-y: hidden;
  }
}

.cart-area .cart-wrap td,
.cart-area .cart-wrap th {
  width: 8%;
  border-bottom: 1px solid rgba(240, 240, 240, 0.5803921569);
  text-align: center;
  font-weight: 400;
  color: #414141;
}

.cart-area .cart-wrap th {
  background: #f8f8f8;
  padding: 20px 0;
}

.cart-area .cart-wrap thead {
  background: #fff;
  color: #505050;
  padding: 40px;
}

.cart-area .cart-area form {
  margin: auto;
  text-align: center;
  background: #fff;
}

.cart-area .cart-wrap .product {
  width: 15%;
  padding: 20px 0;
}

.cart-area .cart-wrap .product ul {
  display: flex;
  text-align: left;
  padding-left: 40px;
  align-items: center;
  position: relative;
}

.cart-area .cart-wrap .product ul li img {
  max-width: 60px;
}

.cart-area .cart-wrap .product ul li.close {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.cart-area .crt-btn {
  text-transform: uppercase;
  color: #7fb432;
}

.cart-area .w-close i {
  color: #f3a952;
}

.cart-area .cart-wrap .product ul li {
  padding-bottom: 5px;
}

.cart-area .cart-wrap .product ul li.first-cart {
  margin-left: 20px;
}

.cart-area .cart-wrap .product a {
  font-size: 16px;
  color: #a5a4a4;
}

.cart-area .cart-wrap .product-2 {
  text-align: left;
  padding-left: 55px;
}

.cart-area .cart-wrap .product a:hover {
  color: #7fb432;
}

.heading-title {
  margin-bottom: 30px;
}

.cart-area .cart-wrap th {
  height: 80px;
  font-weight: 700;
}

.cart-area .order-wrap {
  padding: 0;
}

.cart-area .cart-wrap tr:nth-child(even) {
  background: #fcfcfc;
}

.cart-area .cart-wrap .quantity {
  position: relative;
}

.cart-area .input-style {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.cart-area .input-style .quantity {
  position: relative;
  width: 120px;
  margin: 0 20px;
}

@media (max-width: 450px) {
  .cart-area .input-style .quantity {
    margin-bottom: 10px;
  }
}

.cart-area .input-style .quantity input {
  height: 40px;
  width: 120px;
  text-align: center;
  border: 0;
}

.cart-area .input-style .quantity input:focus {
  outline: none;
}

@media (max-width: 768px) {
  .cart-area .input-style .quantity input {
    height: 40px;
    margin-top: 0;
  }
}

.cart-area .input-style .qtybutton {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #e9ecef;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.cart-area .input-style .qtybutton.inc {
  left: auto;
  right: 0;
}

.cart-area .input-style a .icon {
  font-size: 30px;
  color: #707070;
  margin: 0 15px;
}

.cart-area .input-style a:hover {
  color: #f9f9f9;
}

.cart-area .submit-btn-area {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 500px) {
  .cart-area .submit-btn-area {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.cart-area .submit-btn-area .left-coupon h3 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 15px;
}

.cart-area .submit-btn-area .left-coupon .form-group {
  position: relative;
  max-width: 370px;
}

.cart-area .submit-btn-area .left-coupon .form-group input {
  width: 320px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 15px;
  margin: 0;
}

.cart-area .submit-btn-area .left-coupon .form-group button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 19px 15px;
  height: 60px;
  background: #7fb432;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

@media (max-width: 500px) {
  .cart-area .submit-btn-area ul {
    margin-top: 20px;
  }
}

.cart-area .submit-btn-area button {
  padding: 15px 40px;
  border: none;
  background: #7fb432;
  color: #fff;
  transition: all 0.3s;
  outline: none;
}

.cart-area .submit-btn-area button:hover {
  background: #7fb432;
}

.cart-area .submit-btn-area .theme-btn {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
}

.cart-area .cart-product-list {
  max-width: 446px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-top: 50px;
}

.cart-area .cart-product-list ul {
  border-top: 1px solid rgba(240, 240, 240, 0.5803921569);
  padding-top: 20px;
}

.cart-area .cart-product-list ul li {
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #414141;
  padding-bottom: 30px;
}

.cart-area .cart-product-list ul li.cart-b {
  border-top: 1px solid rgba(240, 240, 240, 0.5803921569);
  color: #7fb432;
  padding-top: 30px;
  font-weight: 600;
}

.cart-area .cart-product-list .c-btn,
.payment-btn .btn-style-1 {
  padding: 10px 20px;
  background: #7fb432;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
}

.cart-area .cart-product-list .c-btn i {
  margin-right: 5px;
}

.cart-area .empty-area {
  text-align: center;
}

.cart-area .empty-area .icon {
  max-width: 300px;
  margin: 0 auto;
}

.cart-area .empty-area p {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
}

/* =============================
27. Checkout-page
 ===============================*/
/* checkout-area */
.checkout-area .caupon-area {
  margin-bottom: 80px;
}

.checkout-area .heading-title h2 {
  font-size: 30px;
}

.checkout-area .caupon-area .coupon-box {
  background: #f2f2f2;
  padding: 20px;
}

.checkout-area .caupon-area .coupon-box p {
  margin-bottom: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.checkout-area .caupon-area .coupon-box p i {
  color: #7fb432;
  margin-right: 10px;
}

.checkout-area .caupon-area .coupon-box p span {
  display: inline-block;
  color: #7fb432;
  margin-left: 10px;
  cursor: pointer;
}

.checkout-area .caupon-area .coupon-form p {
  margin-bottom: 15px;
  color: #7b7b7b;
}

.checkout-area .caupon-area .coupon-form {
  display: none;
  padding: 20px;
}

.checkout-area .caupon-area .coupon-form span {
  margin-bottom: 20px;
  display: block;
  color: #7b7b7b;
}

.checkout-area .caupon-area .input-wrap {
  position: relative;
}

.checkout-area .caupon-area .coupon-form input {
  width: 100%;
  height: 50px;
  border: 1px solid #ebebeb;
  padding-left: 20px;
  border-radius: 0;
}

.checkout-area .caupon-area .coupon-form input:focus {
  outline: none;
  box-shadow: none;
}

.checkout-area .caupon-area .coupon-form button {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  background: #7fb432;
  color: #fff;
  width: 108px;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.checkout-area .biling-item .billing-adress label {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  color: #1c263a;
  margin-bottom: 5px;
}

.checkout-area .biling-item .billing-adress .form-style input,
.checkout-area .biling-item .billing-adress .form-style select {
  width: 100%;
  margin-bottom: 25px;
  padding-left: 10px;
  height: 50px;
  border-radius: 5px;
  border: none;
  background: #f2f2f2;
}

.checkout-area .biling-item .billing-adress .form-style input:focus,
.checkout-area .biling-item .billing-adress .form-style select:focus {
  outline: none;
  box-shadow: none;
}

.checkout-area .biling-item .billing-adress .form-style select {
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  -moz-appearance: none;
  background: #f2f2f2;
  position: relative;
}

.checkout-area .note-area {
  margin-bottom: 50px;
}

.checkout-area .note-area label {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  color: #1c263a;
  margin-bottom: 5px;
}

.checkout-area .note-area textarea {
  background: #f2f2f2;
  width: 100%;
  border: 0;
  padding: 15px;
  height: 256px;
}

.checkout-area .checkout-order-area {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #f6f6f6;
  padding: 40px;
}

@media (max-width: 575px) {
  .checkout-area .checkout-order-area {
    padding: 20px;
  }
}

.checkout-area .checkout-order-area h3 {
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

.checkout-area .oreder-item .title {
  padding-bottom: 10px;
}

.checkout-area .oreder-item .title.bg-title {
  padding: 30px;
  background: #ebebeb;
}

.checkout-area .oreder-item .title h2 {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.checkout-area .oreder-item .title.s1 {
  border-bottom: 1px solid rgba(93, 102, 111, 0.15);
  padding-bottom: 25px;
  padding-top: 25px;
  margin-bottom: 20px;
}

.checkout-area .oreder-item .title.s1 h2 {
  margin-bottom: 0;
}

.checkout-area .oreder-item .oreder-product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  padding: 20px 0;
}

.checkout-area .oreder-item .oreder-product .images img {
  width: 60px;
}

.checkout-area .oreder-item .oreder-product .product ul .first-cart {
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  color: #233d50;
}

.checkout-area .oreder-item .oreder-product span {
  font-weight: 500;
  font-size: 16px;
  color: #233d50;
}

.checkout-area .payment-option {
  margin-top: 30px;
}

.checkout-area .payment-option h3 {
  margin-bottom: 20px;
  padding-bottom: 0px;
}

.checkout-area .payment-option .payment-select ul li {
  margin-right: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  font-weight: 500;
  color: #5b5b5b;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkout-area .payment-option .payment-select ul li input {
  background: transparent;
  border-radius: 100%;
  height: 16px;
  width: 16px;
  appearance: none;
  -webkit-appearance: none;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px #707070;
  margin-right: 10px;
}

.checkout-area .payment-option .payment-select ul li input:checked {
  background: #707070;
}

.checkout-area .payment-option .payment-bottom {
  padding-top: 20px;
  border-top: 1px solid #d9d9d9;
  margin-top: 40px;
}

.checkout-area .payment-option .payment-btn {
  text-align: right;
  margin-top: 20px;
}

.mission-para {
  font-size: 18px;
}

/* Accordion */
.wpo-service-single-section .wpo-service-single-wrap .wpo-benefits-section .accordion {
  border-top: 1px solid #e1e1e1;
}

.wpo-service-single-section .wpo-service-single-wrap .wpo-benefits-section .accordion-item {
  border: 0;
  border-bottom: 1px solid #e1e1e1;
}

.wpo-service-single-section .wpo-service-single-wrap .wpo-benefits-section .accordion-item .accordion-body {
  padding-left: 0;
  padding-right: 0;
}

.wpo-service-single-section .wpo-service-single-wrap .wpo-benefits-section .accordion-item button {
  padding: 20px;
  border: 0;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 20px;
  color: #373b3e;
  text-align: left;
}

.wpo-service-single-section .wpo-service-single-wrap .wpo-benefits-section .accordion-item button::after {
  background: none;
  font-family: "themify";
  content: "\e622";
  font-size: 15px;
  transform: rotate(0deg);
  font-weight: 700;
}

.wpo-service-single-section .wpo-service-single-wrap .wpo-benefits-section .accordion-item button.collapsed {
  color: #373b3e;
}

.wpo-service-single-section .wpo-service-single-wrap .wpo-benefits-section .accordion-item button.collapsed::after {
  content: "\e61a";
}

.wpo-service-single-section .wpo-service-single-wrap .wpo-benefits-section .accordion-item button:focus {
  box-shadow: none;
  outline: none;
  border-color: #e1e1e1;
}

.wpo-service-single-section .wpo-service-single-wrap .wpo-benefits-section .accordion-item .accordion-collapse {
  border: 0;
}

.wpo-service-single-section .wpo-service-single-wrap .wpo-benefits-section .accordion-button {
  background: transparent;
}

.wpo-faq-section .wpo-section-title {
  text-align: center;
}

.wpo-faq-section .wpo-section-title h2 {
  display: inline-block;
}

.wpo-faq-section .accordion-item {
  border: 0;
  border: 1px solid #e1e1e1;
  padding: 5px 20px;
  margin-bottom: 20px;
}

.wpo-faq-section .accordion-item .accordion-body {
  padding-left: 0;
  padding-right: 0;
}

.wpo-faq-section .accordion-item button {
  padding: 20px;
  border: 0;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 18px;
  color: #373b3e;
  font-weight: 700;
  text-align: left;
  /* font-family: "Roboto", sans-serif; */
}

.wpo-faq-section .accordion-item button::after {
  background: none;
  font-family: "themify";
  content: "\e622";
  font-size: 15px;
  transform: rotate(0deg);
  font-weight: 700;
}

.wpo-faq-section .accordion-item button.collapsed {
  color: #373b3e;
}

.wpo-faq-section .accordion-item button.collapsed::after {
  content: "\e61a";
}

.wpo-faq-section .accordion-item button:focus {
  box-shadow: none;
  outline: none;
  border-color: #e1e1e1;
}

.wpo-faq-section .accordion-item .accordion-collapse {
  border: 0;
}

.wpo-faq-section .accordion-button {
  background: transparent;
}

ul.social-media i {
  width: 50px !important;
  height: 50px !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  border-radius: 51% !important;
  font-size: 22px !important;
  color: white !important;
  background-color: #7fb432 !important;
}

ul.social-media i:hover {
  background-color: #eb930d !important;
}

.align-items-stretch {
  align-items: stretch !important;
  height: 100%;
}

/* Document Css */
.get-involved i {
  color: white;
  background-color: #000000;
}

.get-involved img {
  width: 100%;
}

.get-involved.develop {
  padding: 30px;
}

.get-involved.develop h3 {
  font-weight: bold;
  margin-bottom: 6px;
}

.get-involved.data {
  padding: 30px;
}

.get-involved.data h3 {
  font-size: 22px;
}

.get-involved.data.contact h6 {
  font-size: 16px;
}

.get-involved.develop.mb-0 {
  margin-bottom: 40px !important;
}

.get-involved.data {
  padding: 30px;
  margin-bottom: 30px;
  border: 1px solid #f1f1f1;
}

body.drak-mode .get-involved.data {
  border: 1px solid #363636;
}

.get-involved.data {
  padding: 40px;
}

.get-involved.develop {
  padding: 30px;
  flex-wrap: wrap;
}

.get-involved.develop i {
  transform: translatex(-0px);
  margin-top: 0px;
  margin-bottom: 20px;
}

.get-involved.develop {
  margin: 0;
  width: 100%;
  margin-bottom: 25px;
}

.get-involved.develop,
.get-involved.data {
  background-color: black;
}

.get-involved.contact a,
.get-involved.contact h6,
.get-involved.data {
  color: #c1c1c1;
}

.get-involved.data.contact {
  border: 1px solid #363636;
}

/* 75. get-involved */
.get-involved img {
  border-radius: 35px;
}

.get-involved svg {
  fill: #fff;
  width: 60px;
  height: auto;
}

.get-involved i {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 20px;
}

.get-involved ul li i {
  background-color: transparent;
  width: 0;
  height: 0;
  padding: 0;
  transform: translateY(0px);
  margin-top: 0px;
}

.get-involved.data {
  width: 86%;
  margin: auto;
  /* background-color: #EB930D; */
  background-color: #f7f7f7;
  border-radius: 40px;
  padding: 30px;
  margin-top: -58px;
  position: relative;
  padding-top: 0;
}

.get-involved i {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 20px;
  transform: translateY(-50px);
  margin-top: -12px;
}

.get-involved.data ul li {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
}

.get-involved.data ul li i {
  padding-right: 20px;
}

.get-involved.data h3 {
  font-weight: bold;
  padding-bottom: 5px;
  margin-top: -30px;
}

.get-involved:hover {
  transform: translateY(-10px);
}

.get-involved.data:hover {
  transform: translateY(0px);
}

/* 76. develop */
.get-involved.develop {
  display: flex;
}

.get-involved.develop i {
  transform: translatex(-50px);
  margin-top: 0px;
}

.get-involved.develop {
  display: flex;
  background-color: white;
  padding: 40px;
  border-radius: 40px;
  padding-left: 0;
  width: 91%;
  margin-left: auto;
  margin-bottom: 25px;
}

.get-involved.develop h3 {
  font-weight: bold;
}

/* 77. get-involved.data.contact */
.get-involved.data.contact {
  width: 100%;
  border: 1px solid #d1d1d1;
  margin: 0;
  margin-top: 62px;
}

.get-involved.contact i {
  margin-left: auto;
}

.get-involved.contact h6 {
  margin-bottom: 0;
  color: #555;
}

.get-involved.contact a {
  color: #555;
  padding-left: 10px;
}

.get-involved.data.contact:hover {
  background-color: #eb930d;
  border: 1px solid #eb930d;
  color: white !important;
}

.get-involved.data.contact:hover a,
.get-involved.data.contact:hover h6,
.get-involved.data.contact:hover h3 {
  color: white;
}

.get-involved.data.contact svg {
  width: 50px;
}

.get-involved.data.contact a,
.get-involved.data.contact h6,
.get-involved.data.contact h3,
ul.instagram-posts figure:before,
.posts.Photo a,
.get-involved,
.get-involved.data.contact {
  transition: 0.3s ease-in-out;
}

/* End */

.wrap-text {
  word-wrap: break-word;
  white-space: normal;
}

p {
  font-size: 18px;
}

@media (max-width: 600px) {
  .footer-stylling {
    flex-direction: column;
  }
}

.footer-link-website {
  color: white !important;
}

.footer-link-website:hover {
  color: #eb9309 !important;
}

.contact-links:hover {
  color: #eb9309 !important;
  /* font-weight: 600; */
}

.contact-div-heightt {
  height: 337px;
}

@media (max-width: 991px) {
  .contact-div-heightt {
    height: auto;
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .pt-71 {
    padding-top: 90px;
  }
}

.logo-resp-height {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsive-service-home {
  height: 395px;
}

@media (max-width: 600px) {
  .responsive-service-home {
    height: 365px;
  }
}

@media (max-width: 1204px) {
  .responsive-support-divs {
    height: 274px;
  }
}

.responsive-blogs {
  height: 556px;
}

@media (max-width: 1296px) {
  .responsive-blogs {
    height: 600px;
  }
}

@media (max-width: 1273px) {
  .responsive-blogs {
    height: 620px;
  }
}

@media (max-width: 1188px) {
  .responsive-blogs {
    height: 640px;
  }
}

@media (max-width: 1149px) {
  .responsive-blogs {
    height: 670px;
  }
}

@media (max-width: 1083px) {
  .responsive-blogs {
    height: 695px;
  }
}

@media (max-width: 1051px) {
  .responsive-blogs {
    height: 725px;
  }
}

@media (max-width: 990px) {
  .responsive-blogs {
    height: 750px;
  }
}

@media (max-width: 926px) {
  .responsive-blogs {
    height: 800px;
  }
}

@media (max-width: 908px) {
  .responsive-blogs {
    height: 860px;
  }
}

@media (max-width: 887px) {
  .responsive-blogs {
    height: 890px;
  }
}

@media (max-width: 838px) {
  .responsive-blogs {
    height: 930px;
  }
}

@media (max-width: 823px) {
  .responsive-blogs {
    height: 960px;
  }
}

@media (max-width: 780px) {
  .responsive-blogs {
    height: 990px;
  }
}

@media (max-width: 773px) {
  .responsive-blogs {
    height: 1035px;
  }
}

.absoute-blog-buttons {
  position: absolute !important;
  bottom: 30px;
}

@media (max-width: 767px) {
  .responsive-blogs {
    height: auto;
  }

  .blog-descriptionn {
    margin-bottom: 100px;
  }
}

.responsive-our-journey-div {
  height: 380px;
}

@media (max-width: 1199px) {
  .responsive-our-journey-div {
    height: 350px;
  }
}

@media (max-width: 1024px) {
  .responsive-our-journey-div {
    height: 330px;
  }
}

@media (max-width: 575px) {
  .responsive-our-journey-div {
    height: 300px;
  }
}

@media (max-width: 1189px) {
  .sevak-army-div-responsive {
    height: 285px;
  }
}

@media (max-width: 991px) {
  .sevak-army-div-responsive {
    height: 255px;
  }
}

@media (max-width: 767px) {
  .sevak-army-div-responsive {
    height: 240px;
  }
}

@media (max-width: 767px) {
  .sevak-army-div-responsive {
    height: 210px;
  }
}

@media (max-width: 443px) {
  .sevak-army-div-responsive {
    height: 230px;
  }
}

@media (max-width: 380px) {
  .sevak-army-div-responsive {
    height: 260px;
  }
}

@media (max-width: 348px) {
  .sevak-army-div-responsive {
    height: 280px;
  }
}

@media (max-width: 300px) {
  .sevak-army-div-responsive {
    height: 310px;
  }
}


@media (max-width: 274px) {
  .sevak-army-div-responsive {
    height: 340px;
  }
}

@media (max-width: 255px) {
  .sevak-army-div-responsive {
    height: 380px;
  }
}

@media (max-width: 223px) {
  .sevak-army-div-responsive {
    height: 450px;
  }
}

@media (max-width: 1143px) {
  .life-saver-army-div-responsive {
    height: 245px;
  }
}

@media (max-width: 991px) {
  .life-saver-army-div-responsive {
    height: 230px;
  }
}

@media (max-width: 767px) {
  .life-saver-army-div-responsive {
    height: 240px;
  }
}

@media (max-width: 767px) {
  .life-saver-army-div-responsive {
    height: 210px;
  }
}

@media (max-width: 409px) {
  .life-saver-army-div-responsive {
    height: 250px;
  }
}

@media (max-width: 256px) {
  .life-saver-army-div-responsive {
    height: 340px;
  }
}

@media (max-width: 217px) {
  .life-saver-army-div-responsive {
    height: 400px;
  }
}

.my-food-checker-div-respo {
  height: 248px;
}

@media (max-width: 767px) {
  .my-food-checker-div-respo {
    height: 220px;
  }
}

@media (max-width: 431px) {
  .my-food-checker-div-respo {
    height: 280px;
  }
}

@media (max-width: 256px) {
  .my-food-checker-div-respo {
    height: 340px;
  }
}

@media (max-width: 217px) {
  .my-food-checker-div-respo {
    height: 400px;
  }
}

@media (max-width: 998px) {
  .mind-unload-div-respo {
    height: 250px;
  }
}

@media (max-width: 767px) {
  .mind-unload-div-respo {
    height: 220px;
  }
}

@media (max-width: 1214px) {
  .akshay-div-responsive {
    height: 250px;
  }
}

@media (max-width: 991px) {
  .akshay-div-responsive {
    height: 230px;
  }
}

@media (max-width: 767px) {
  .akshay-div-responsive {
    height: 210px;
  }
}

@media (max-width: 404px) {
  .akshay-div-responsive {
    height: 280px;
  }
}

@media (max-width: 258px) {
  .akshay-div-responsive {
    height: 340px;
  }
}

@media (max-width: 217px) {
  .akshay-div-responsive {
    height: 390px;
  }
}

@media (max-width: 1149px) {
  .young-jains-div-responsive {
    height: 278px;
  }
}

@media (max-width: 996px) {
  .young-jains-div-responsive {
    height: 290px;
  }
}

@media (max-width: 991px) {
  .young-jains-div-responsive {
    height: 260px;
  }
}

@media (max-width: 767px) {
  .young-jains-div-responsive {
    height: 210px;
  }
}

@media (max-width: 409px) {
  .young-jains-div-responsive {
    height: 280px;
  }
}

@media (max-width: 256px) {
  .young-jains-div-responsive {
    height: 340px;
  }
}

@media (max-width: 222px) {
  .young-jains-div-responsive {
    height: 440px;
  }
}

@media (max-width: 1229px) {
  .reform-army-div-responsive {
    height: 250px;
  }
}

@media (max-width: 1033px) {
  .reform-army-div-responsive {
    height: 270px;
  }
}

@media (max-width: 991px) {
  .reform-army-div-responsive {
    height: 240px;
  }
}

@media (max-width: 767px) {
  .reform-army-div-responsive {
    height: 210px;
  }
}

@media (max-width: 409px) {
  .reform-army-div-responsive {
    height: 230px;
  }
}

@media (max-width: 256px) {
  .reform-army-div-responsive {
    height: 300px;
  }
}

@media (max-width: 222px) {
  .reform-army-div-responsive {
    height: 370px;
  }
}

.success-story-responsive {
  height: 320px;
}

@media (max-width: 1198px) {
  .success-story-responsive {
    height: 360px;
  }
}

@media (max-width: 856px) {
  .success-story-responsive {
    height: 390px;
  }
}

@media (max-width: 785px) {
  .success-story-responsive {
    height: 410px;
  }
}

/* @media (max-width:767px) {
  .success-story-responsive {
    height: 250px;
  }
} */

@media (max-width: 767px) {
  .success-story-responsive {
    height: auto;
  }
}

.respo-volunteer-opportunities {
  height: 248px;
}

@media (max-width: 1078px) {
  .respo-volunteer-opportunities {
    height: 275px;
  }
}

@media (max-width: 991px) {
  .respo-volunteer-opportunities {
    height: 245px;
  }
}

@media (max-width: 767px) {
  .respo-volunteer-opportunities {
    height: auto;
  }
}

.volunteer-opportunities-get-started {
  height: 248px;
}

@media (max-width: 1042px) {
  .volunteer-opportunities-get-started {
    height: 270px;
  }
}

@media (max-width: 991px) {
  .volunteer-opportunities-get-started {
    height: 240px;
  }
}

@media (max-width: 767px) {
  .volunteer-opportunities-get-started {
    height: auto;
  }
}

.upcoming-volunteer-events {
  height: 360px;
}

@media (max-width: 1262px) {
  .upcoming-volunteer-events {
    height: 380px;
  }
}

@media (max-width: 1199px) {
  .upcoming-volunteer-events {
    height: 300px;
  }
}

@media (max-width: 1024px) {
  .upcoming-volunteer-events {
    height: 280px;
  }
}

@media (max-width: 961px) {
  .upcoming-volunteer-events {
    height: 280px;
  }
}

@media (max-width: 767px) {
  .upcoming-volunteer-events {
    height: 320px;
  }
}

@media (max-width: 648px) {
  .upcoming-volunteer-events {
    height: 370px;
  }
}

@media (max-width: 635px) {
  .upcoming-volunteer-events {
    height: 370px;
  }
}

@media (max-width: 599px) {
  .upcoming-volunteer-events {
    height: 370px;
  }
}

@media (max-width: 575px) {
  .upcoming-volunteer-events {
    height: 290px;
  }
}

@media (max-width: 395px) {
  .upcoming-volunteer-events {
    height: 320px;
  }
}

@media (max-width: 327px) {
  .upcoming-volunteer-events {
    height: 360px;
  }
}

@media (max-width: 323px) {
  .upcoming-volunteer-events {
    height: 390px;
  }
}

.why-donate-responsive {
  height: 380px;
}

@media (max-width: 1251px) {
  .why-donate-responsive {
    height: 400px;
  }
}

@media (max-width: 1112px) {
  .why-donate-responsive {
    height: 430px;
  }
}

@media (max-width: 1036px) {
  .why-donate-responsive {
    height: 460px;
  }
}

@media (max-width: 991px) {
  .why-donate-responsive {
    height: auto;
  }
}

.donate-key-features {
  height: 215px;
}

@media (max-width: 1274px) {
  .donate-key-features {
    height: 245px;
  }
}

@media (max-width: 991px) {
  .donate-key-features {
    height: 228px;
  }
}

@media (max-width: 767px) {
  .donate-key-features {
    height: auto;
  }
}

.error-message {
  color: red;
  /* margin-top: 10px; */
}

.phone-focuss:focus {
  border: 2px solid #86b7fe !important;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
}

.form-control:focus {
  border-color: #eeeeee;
  box-shadow: none;
}

@media (max-width: 1560px) {
  .responsive-description {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .responsive-description {
    font-size: 28px;
  }
}

@media (max-width: 528px) {
  .responsive-description {
    font-size: 22px;
  }
}

.responsive-home-features {
  height: 300px;
}

@media (max-width: 1099px) {
  .responsive-home-features {
    height: 340px;
  }
}

@media (max-width: 1057px) {
  .responsive-home-features {
    height: 360px;
  }
}

@media (max-width: 991px) {
  .responsive-home-features {
    height: 160px;
  }
}

@media (max-width: 499px) {
  .responsive-home-features {
    height: 200px;
  }
}

@media (max-width: 410px) {
  .responsive-home-features {
    height: 240px;
  }
}

@media (max-width: 354px) {
  .responsive-home-features {
    height: auto;
  }
}

@media (max-width: 1399px) {
  .header__wrapper {
    gap: 25px;
  }

  .header__logo__image {
    width: 160px !important;
  }

  .mainMenu>ul>li a {
    font-size: 13px !important;
  }
}

/* .header-right-responsive {
  display: none;
} */

@media (max-width: 1232px) {
  .header-right-responsive {
    display: flex;
  }
}


@media (max-width: 1232px) {
  .button-display {
    display: none;
  }
}

@media (max-width: 767px) {
  .button-display {
    display: block;
  }

  .header-right-responsive .header__button {
    display: none !important;
  }
}

.dropdown_menu-2 {
  z-index: 9999999999999999 !important;
}

.contactBlock__text2 span {
  color: #000000;
}

.contactBlock__text2:hover span {
  color: #eb9309;
}



/* Policy css start */ 

.top-policy-margin {
  margin-top: 30px;
}
.policiess {
  font-size: 18px;
}

.policies p {
  margin-bottom: 12px;
}

.privacy-policy-intro-list {
  list-style-type: decimal;
  padding-left: 20px;
}


.privacy-policy-intro-list li {
  margin-bottom: 8px;
  gap: 12px;
  font-weight: 400;
}

.privacy-policy-intro-list li a {
  color: #3B82F6;
}

.privacy-policy-intro-list li a:hover {
  color: #101010;
}

.section-start {
  margin-top: 2.4rem;
  margin-bottom: 1rem;
}

.policy-heading {
  font-size: 2.5rem;
  font-weight: 700;
  color: #101010;
}

.privacy-policy-intro-list2 {
  list-style-type: decimal;
  padding-left: 20px;
}

.privacy-policy-intro-list2 li::marker {
  font-size: 1.4rem;
  font-weight: bold;
  color: #333;
}

.unordered-listtt li::marker {
  font-size: 1rem;
}

.policy-list-items li h4 {
  font-size: 24px;
  font-weight: 600;
  color: #101010;
  margin-bottom: 15px;
}

.unordered-listtt {
  list-style-type: disc;
  padding-left: 22px;
}

.policy-section-list {
  padding-top: 10px;
  margin-bottom: 20px;
}

.policy-section-list li {
  padding-top: 10px;
}

.policy-section-list p {
  margin-bottom: 0px !important;
}

.policy-section-list h5 {
  padding-bottom: 8px;
  font-size: 20px;
  font-weight: 600;
}

.policies-section {
  margin-bottom: 30px;
}

.policies-footer {
  width: 100%;
  text-align: center;
  margin-top: 60px;
  font-size: 18px;
  font-weight: 600;
}

.policies-footer a {
  color: #3B82F6;
  padding-left: 5px;
  padding-right: 5px;
}

.policies-footer a:hover {
  color: #101010;
}

.terms-ssection-1 {
  margin-top: 10px;
  margin-bottom: 40px;
}

.terms-section-1 h3 {
  font-size: 2rem;
  font-weight: 700;
  margin-top: 25px;
  padding-bottom: 20px;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.privacy-policy-intro-list2 ol {
  list-style-type: decimal;
  padding-left: 20px;
  margin-bottom: 20px;
}

.privacy-policy-intro-list2 ol li::marker {
  font-size: 1rem;
  color: #333;
}

.policy-note {
  font-weight: 700;
  font-size: 18px;
  padding-top: 20px;
}

.initiatives-section {
  padding-bottom: 25px;
}

.initiatives-section h4 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 18px;
}

.policy-content p {
  text-align: justify !important;
}

.policy-content li {
  text-align: justify !important;
}

.policiess li {
  margin-bottom: 10px;
}

.css-1idtd5j {
  width: 100% !important;
}

.keyFeatureBlock__text {
  font-size: 18px;
}

.aboutContent__text {
  font-size: 20px;
}

a:hover {
  color: #000;
}

.success-messege {
  font-size: 20px;
  color: green;
}

@media (max-width: 1199px) {
  .responsive-hero-imgggggg {
    margin-top: 30px;
  }
}

@media (max-width: 991px) {
  .responsive-hero-imgggggg {
    margin-top: 50px;
  }
}

@media (max-width: 831px) {
  .responsive-hero-imgggggg {
    margin-top: 70px;
  }
}

@media (max-width: 699px) {
  .responsive-hero-imgggggg {
    margin-top: 80px;
  }
}

@media (max-width: 599px) {
  .responsive-hero-imgggggg {
    margin-top: 100px;
  }
}

@media (max-width: 515px) {
  .responsive-hero-imgggggg {
    margin-top: 120px;
  }
}

@media (max-width: 399px) {
  .responsive-hero-imgggggg {
    margin-top: 140px;
  }
}